import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../components/ui/resizable';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';

import useTriggerAction from '../../hooks/useTriggerAction';
import useFileManager from '../../hooks/useFileManager';

import FilesProvider from '../../contexts/FilesContext';
import FileNavigationProvider from '../../contexts/FileNavigationContext';
import SelectionProvider from '../../contexts/SelectionContext';
import ClipBoardProvider from '../../contexts/ClipboardContext';
import LayoutProvider from '../../contexts/LayoutContext';

import MyAssetLibrary from '../../components/virtual/solutionedit/MyAssetLibrary';
import Loader from '../../components/Loader/Loader';
import ContentList from '../../components/virtual/solutionedit/ContentList';
import PropertyList from '../../components/virtual/solutionedit/PropertyList';
import ObjectList from '../../components/virtual/solutionedit/ObjectList';

import { getStoreSession } from '../../services/StoreService';
import { customizationCreate, solutionDetail } from '../../services/SolutionService';
import Actions from '../../components/virtual/Explorer/Actions/Actions';
import ProductList from '../../components/virtual/solutionedit/ProductList';
import AppList from '../../components/virtual/solutionedit/AppList';
import ThemesList from '../../components/virtual/solutionedit/ThemesList';
import ViewerEditor from '../../components/layout/SolutionEdit/ViewerEditor';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { Button } from '../../components/ui/button';

const SolutionView = () => {
  const { solutionId } = useParams();
  const [solution, setSolution] = useState({});
  const [currentRoom, setCurrentRoom] = useState(null);

  const [roomList, setRoomList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const filterRoomData = (roomList) => {
    const startRoom = roomList.find((room) => room.isDefault === true);
    setRoomList(roomList);
    setCurrentRoom(startRoom);
  };

  const handleCurrentRoom = (roomId) => {
    if (roomId === currentRoom.id) return;
    const room = solution.ThemeStyle.Room.find((room) => room.id === roomId);
    setCurrentRoom(room);
  };

  const getSolution = async () => {
    try {
      const response = await solutionDetail(solutionId);

      if (response.success) {
        setSolution(response.data);
        filterRoomData(response.data.ThemeStyle.Room);
      } else {
        setSolution({});
      }
    } catch (error) {
      setSolution({});
    }
  };

  const addObjectItem = async (data) => {
    try {
      const response = await customizationCreate(data);

      if (response.success) {
        toast.success('Item added to object list.');
      } else {
        toast.error('Some error occured. Please try again.');
      }
    } catch (error) {
      toast.error('Some error occured. Please try again.');
    }
  };

  useEffect(() => {
    (async () => {
      await getSolution();
    })();
  }, [solutionId]);

  const handleCanvasDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('application/json');
    const item = JSON.parse(data);

    const newItem = {
      store_id: solution.store_id,
      solution_id: solution.id,
      theme_room_id: currentRoom.id,
      market_product_id: item.id,
      is_product: item.is_product,
    };

    addObjectItem(newItem);
  };

  const handleSelect = (id, item) => {
    if (item.modelData.type === 'interior' || item.modelData.type === 'product') {
      setSelectedId(id === selectedId ? null : id);
      setSelectedModel(id === selectedId ? null : item);
    }
  };
  //! below two function can be merged in one
  const handleTransformChange = (id, type, newPosition, newRotation) => {
    setCurrentRoom((prevState) => {
      return {
        ...prevState,
        [type]: prevState[type].map((item) =>
          item.id === id
            ? {
                ...item,
                modelData: {
                  ...item.modelData,
                  position: newPosition,
                  rotation: newRotation,
                },
              }
            : item
        ),
      };
    });

    setSelectedModel((prevState) => {
      return {
        ...prevState,
        modelData: {
          ...prevState.modelData,
          position: newPosition,
          rotation: newRotation,
        },
      };
    });

    setSolution((prevState) => {
      return {
        ...prevState,
        ThemeStyle: {
          ...prevState.ThemeStyle,
          Room: prevState.ThemeStyle.Room.map((room) =>
            room.id === currentRoom.id
              ? {
                  ...room,
                  [type]: room[type].map((item) =>
                    item.id === id
                      ? {
                          ...item,
                          modelData: {
                            ...item.modelData,
                            position: newPosition,
                            rotation: newRotation,
                          },
                        }
                      : item
                  ),
                }
              : room
          ),
        },
      };
    });
  };

  const handlePhysicsChange = (id, type, value) => {
    setCurrentRoom((prevState) => {
      return {
        ...prevState,
        [type]: prevState[type].map((item) =>
          item.id === id
            ? {
                ...item,
                modelData: {
                  ...item.modelData,
                  physics: value,
                },
              }
            : item
        ),
      };
    });

    setSelectedModel((prevState) => {
      return {
        ...prevState,
        modelData: {
          ...prevState.modelData,
          physics: value,
        },
      };
    });

    setSolution((prevState) => {
      return {
        ...prevState,
        ThemeStyle: {
          ...prevState.ThemeStyle,
          Room: prevState.ThemeStyle.Room.map((room) =>
            room.id === currentRoom.id
              ? {
                  ...room,
                  [type]: room[type].map((item) =>
                    item.id === id
                      ? {
                          ...item,
                          modelData: {
                            ...item.modelData,
                            physics: value,
                          },
                        }
                      : item
                  ),
                }
              : room
          ),
        },
      };
    });
  };

  const handleCanvasClick = () => {
    setSelectedId(null);
    setSelectedModel(null);
  };

  const handleItemSelectOnObjectList = (roomId, id, item) => {
    if (roomId !== currentRoom.id) {
      const room = solution.ThemeStyle.Room.find((room) => room.id === roomId);
      setCurrentRoom(room);
    }
    handleSelect(id, item);
  };

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={75}>
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel defaultSize={60} maxSize={70}>
            <div className="relative h-full bg-darkGray pr-px pt-px">
              <Tabs defaultValue="editor" className="flex h-full flex-col">
                <div className="flex w-full items-center justify-between bg-darkGray">
                  <TabsList className="justify-start">
                    <TabsTrigger className="pb-[3px] pl-[15px] pr-[25px] pt-[7px] text-[11px]" value="editor">
                      Editor
                    </TabsTrigger>
                    <TabsTrigger className="pb-[3px] pl-[15px] pr-[25px] pt-[7px] text-[11px]" value="firstperson">
                      First Person
                    </TabsTrigger>
                    <TabsTrigger className="pb-[3px] pl-[15px] pr-[25px] pt-[7px] text-[11px]" value="virtualreality">
                      VirtualReality
                    </TabsTrigger>
                  </TabsList>

                  <div className="flex items-center justify-between gap-1">
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <Button className="h-7 rounded-none bg-muted px-2 text-[11px] font-normal capitalize text-foreground">
                          Add Object
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem>Room</DropdownMenuItem>
                        <DropdownMenuItem>3D Model</DropdownMenuItem>
                        <DropdownMenuItem>App</DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <Button className="h-7 rounded-none bg-muted px-2 text-[11px] font-normal capitalize text-foreground">
                      Save & Publish
                    </Button>
                  </div>
                </div>
                <div className="flex-1 overflow-hidden bg-lightGray11 p-1.5">
                  <TabsContent value="editor" className="h-full">
                    <ViewerEditor
                      handleCanvasDrop={handleCanvasDrop}
                      currentRoom={currentRoom}
                      handleTransformChange={handleTransformChange}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                      handleCanvasClick={handleCanvasClick}
                    />
                  </TabsContent>
                  <TabsContent value="firstperson" className="h-full">
                    <div>Firstperson</div>
                  </TabsContent>
                  <TabsContent value="virtualreality" className="h-full">
                    <div>Virtual Reality</div>
                  </TabsContent>
                </div>
              </Tabs>
            </div>
          </ResizablePanel>
          <ResizableHandle className="bg-darkGray" />
          <ResizablePanel defaultSize={40} maxSize={50}>
            <ContentUI />
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
      <ResizableHandle className="bg-lightGray3" />
      <ResizablePanel defaultSize={25} className="max-w-[450px]" minSize={10} maxSize={30}>
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel defaultSize={50} maxSize={80}>
            <div className="h-full">
              <PropertyList
                selectedModel={selectedModel}
                handleTransformChange={handleTransformChange}
                handlePhysicsChange={handlePhysicsChange}
              />
            </div>
          </ResizablePanel>
          <ResizableHandle className="bg-lightGray3" />
          <ResizablePanel defaultSize={50} maxSize={80}>
            <div className="h-full">
              <ObjectList
                roomList={roomList}
                handleCurrentRoom={handleCurrentRoom}
                selectedId={selectedId}
                currentRoomId={currentRoom?.id}
                handleItemSelectOnObjectList={handleItemSelectOnObjectList}
              />
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default SolutionView;

const ContentUI = () => {
  const triggerAction = useTriggerAction();
  const {
    files,
    handleCreateFolder,
    handleFileUploading,
    handleFileUploaded,
    handleRename,
    handleDelete,
    handlePaste,
    handleLayoutChange,
    handleRefresh,
    handleFileOpen,
    handleError,
    handleDownload,
    fileUploadConfig,
    isLoading,
  } = useFileManager();

  const [optionSelected, setOptionSelected] = useState(1);
  const [contentSelected, setContentSelected] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [showThemes, setShowThemes] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [draggedItem, setDraggedItem] = useState(null);
  const store = getStoreSession();

  const handleShow = (option, data) => {
    setContentSelected(data);
    setOptionSelected(option);
    if (option === 1) {
      setShowContent(true);
      setShowProduct(false);
      setShowApp(false);
      setShowThemes(false);
    } else if (option === 2) {
      setShowContent(false);
      setShowProduct(true);
      setShowApp(false);
      setShowThemes(false);
      // setContentSelected({});
    } else if (option === 3) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(true);
      setShowThemes(false);
      // setContentSelected({});
    } else if (option === 4) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(false);
      setShowThemes(true);
    }
  };

  return (
    <div className="flex h-full items-center justify-center bg-darkGray pr-0.5">
      <FilesProvider filesData={files} onError={handleError}>
        <FileNavigationProvider defaultPath="">
          <SelectionProvider onDownload={handleDownload}>
            <ClipBoardProvider onPaste={handlePaste}>
              <LayoutProvider layout="grid">
                <ResizablePanelGroup direction="horizontal">
                  <ResizablePanel defaultSize={20} maxSize={30}>
                    <MyAssetLibrary
                      optionSelected={optionSelected}
                      handleShow={handleShow}
                      contentSelected={contentSelected}
                    />
                  </ResizablePanel>
                  <ResizableHandle className="bg-darkGray" />
                  <ResizablePanel defaultSize={80} maxSize={85}>
                    <div className="relative h-full">
                      {showContent && (
                        <>
                          <Loader isLoading={isLoading} />
                          <ContentList
                            setDraggedItem={setDraggedItem}
                            contentSelected={contentSelected}
                            triggerAction={triggerAction}
                            onFileOpen={handleFileOpen}
                            onLayoutChange={handleLayoutChange}
                            onRefresh={handleRefresh}
                            onCreateFolder={handleCreateFolder}
                            onRename={handleRename}
                            enableFilePreview
                          />
                        </>
                      )}

                      {showProduct && <ProductList setDraggedItem={setDraggedItem} />}
                      {showApp && <AppList />}
                      {showThemes && <ThemesList />}
                    </div>
                    <Actions
                      fileUploadConfig={fileUploadConfig}
                      onFileUploading={handleFileUploading}
                      onFileUploaded={handleFileUploaded}
                      onDelete={handleDelete}
                      onRefresh={handleRefresh}
                      maxFileSize={50485760}
                      filePreviewPath={`${process.env.REACT_APP_BLOB_CONTAINER_URL}${store.id}`}
                      acceptedFileTypes=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .svg, .SVG, .glb, .GLB, .mp3, .MP3, .mp4, .MP4"
                      triggerAction={triggerAction}
                    />
                  </ResizablePanel>
                </ResizablePanelGroup>
              </LayoutProvider>
            </ClipBoardProvider>
          </SelectionProvider>
        </FileNavigationProvider>
      </FilesProvider>
    </div>
  );
};
