// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rename-file {
  position: relative;
  min-width: 69%;
  max-width: -webkit-fill-available;
  text-align: center;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 11px;
  padding: 1px 5px;
  resize: none;
  field-sizing: content;
  overflow-y: hidden;
  background-color: #535353;
  color: white;

  &:focus {
    outline: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NameInput/NameInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;;EAEZ;IACE,aAAa;EACf;AACF","sourcesContent":[".rename-file {\n  position: relative;\n  min-width: 69%;\n  max-width: -webkit-fill-available;\n  text-align: center;\n  border: 1px solid white;\n  border-radius: 4px;\n  font-size: 11px;\n  padding: 1px 5px;\n  resize: none;\n  field-sizing: content;\n  overflow-y: hidden;\n  background-color: #535353;\n  color: white;\n\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
