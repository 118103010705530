const keyStore = 'auth.store';

const setStoreSession = (store) => {
  localStorage.setItem(keyStore, JSON.stringify(store));
};

const getStoreSession = () => {
  const store = localStorage.getItem(keyStore);

  return JSON.parse(store);
};

const storeList = async (tenantId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storelist?tenant_id=${tenantId}`);
        const jsonResponse = await response.json();

        // console.log(jsonResponse);
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const setStore = async (store) =>
  new Promise((resolve, reject) => {
    try {
      if (store) {
        setStoreSession(store);
        resolve('Store set successfully.');
      } else {
        reject(new Error('Error occured. Please try again.'));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  });

export { storeList, setStore, setStoreSession, getStoreSession };
