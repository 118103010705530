import './Checkbox.css';

const Checkbox = ({ name, id, checked, onClick, onChange, className = '', title, disabled }) => (
  <input
    className={`fm-checkbox ${className}`}
    type="checkbox"
    name={name}
    id={id}
    checked={checked}
    onClick={onClick}
    onChange={onChange}
    title={title}
    disabled={disabled}
  />
);

export default Checkbox;
