import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { Badge } from '../../components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';

import { Drawer, DrawerContent } from '../../components/ui/drawer';
import { Dialog } from '../../components/ui/dialog';
import Modal from '../../components/layout/SolutionList/Modal';

import { getStoreSession } from '../../services/StoreService';
import { solutionList } from '../../services/SolutionService';
import { cn, formatHumanReadable } from '../../utils/common';

import SolutionSetting from '../../components/layout/SolutionList/SolutionSetting';
import SolutionCreate from '../../components/layout/SolutionList/SolutionCreate';
import { EllipsisIcon, plusIcon } from '../../common/icon';

function SolutionList() {
  const title = 'Solutions List';
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState({});
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [solutionVisibility, setSolutionVisibility] = useState({
    create: false,
    publish: false,
    setting: false,
    delete: false,
  });

  const getSolutions = async () => {
    const store = getStoreSession();
    try {
      const response = await solutionList(store.id);
      if (response.success) {
        setSolutions(response.data);
      } else {
        setSolutions([]);
      }
    } catch (error) {
      setSolutions([]);
    }
  };

  const handleShowModal = (value) => {
    setShowThemeModal(value);
  };

  const handleSelectedSolution = (keyName, solution) => {
    if (solution) {
      setSelectedSolution(solution);
    }

    if (keyName === 'edit') {
      navigate(`/solution/${solution.id}`);
    } else {
      if (keyName === 'create' || keyName === 'setting') {
        setIsDrawerOpen(true);
      } else {
        handleShowModal(true);
      }

      setSolutionVisibility((prevState) => {
        const newState = { ...prevState };
        Object.keys(newState).forEach((k) => (newState[k] = false));
        newState[keyName] = true;
        return newState;
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getSolutions();
    })();
  }, []);

  const handleDrawerChange = () => {
    setIsDrawerOpen(false);

    setSolutionVisibility((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((k) => (newState[k] = false));
      return newState;
    });
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex h-full flex-col bg-lightGray3">
        <div className="flex h-11 items-center bg-lightBlack4 px-6">
          <div
            onClick={() => handleSelectedSolution('create')}
            className="flex cursor-pointer items-center gap-2 text-[15px] text-white/60 transition-all duration-300 hover:text-white"
          >
            <img src={plusIcon} width={12} height={12} alt="plus" />
            New
          </div>
        </div>
        <Dialog open={showThemeModal} onOpenChange={() => handleShowModal(false)}>
          <Drawer direction="right" open={isDrawerOpen} onOpenChange={handleDrawerChange}>
            <DrawerContent
              className={cn(
                solutionVisibility.create && 'w-[400px]',
                solutionVisibility.setting && 'md:3/4 w-full lg:w-1/2'
              )}
            >
              {solutionVisibility.create && (
                <SolutionCreate getSolutions={getSolutions} handleDrawerClose={() => setIsDrawerOpen(false)} />
              )}
              {solutionVisibility.setting && (
                <SolutionSetting
                  selectedSolution={selectedSolution}
                  getSolutions={getSolutions}
                  handleDrawerClose={() => setIsDrawerOpen(false)}
                />
              )}
            </DrawerContent>
          </Drawer>

          <Modal
            solutionVisibility={solutionVisibility}
            selectedSolution={selectedSolution}
            getSolutions={getSolutions}
            handleShowModal={handleShowModal}
          />
        </Dialog>
        <div className="flex w-full flex-col gap-4 p-[2rem_1.7rem] text-white">
          <h1 className="text-xl font-bold">Solutions</h1>
          <Table className="text-[13px] font-normal">
            <TableHeader>
              <TableRow className="border-darkGray">
                <TableHead>Name</TableHead>
                <TableHead>Actions</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Market</TableHead>
                <TableHead>Created By</TableHead>
                <TableHead>Created On</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-white">
              {solutions &&
                solutions.map((solution) => (
                  <TableRow className="border-b border-darkGray" key={solution.id}>
                    <TableCell className="hover:underline">
                      <Link to={`/solution/${solution.id}`}>{solution.name}</Link>
                    </TableCell>
                    <TableCell className="relative">
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <EllipsisIcon size={14} className="h-full cursor-pointer hover:bg-darkGray" />
                        </DropdownMenuTrigger>

                        <DropdownMenuContent
                          className="rounded-xs min-w-40 bg-darkGray text-white"
                          align="start"
                          sideOffset={12}
                        >
                          <DropdownMenuItem
                            className="cursor-pointer py-2.5 text-xs focus:bg-lightBlack4"
                            onClick={() => handleSelectedSolution('edit', solution)}
                          >
                            Edit
                          </DropdownMenuItem>
                          <DropdownMenuSeparator className="my-0" />
                          <DropdownMenuItem
                            className="cursor-pointer py-2.5 text-xs focus:bg-lightBlack4"
                            onClick={() => handleSelectedSolution('publish', solution)}
                          >
                            Publish
                          </DropdownMenuItem>{' '}
                          <DropdownMenuSeparator className="my-0" />
                          <DropdownMenuItem
                            className="cursor-pointer py-2.5 text-xs focus:bg-lightBlack4"
                            onClick={() => handleSelectedSolution('setting', solution)}
                          >
                            Settings
                          </DropdownMenuItem>{' '}
                          <DropdownMenuSeparator className="my-0" />
                          <DropdownMenuItem
                            className="cursor-pointer py-2.5 text-xs focus:bg-lightBlack4"
                            onClick={() => handleSelectedSolution('delete', solution)}
                          >
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>{' '}
                    </TableCell>
                    <TableCell>
                      <Badge
                        variant={solution.status === 'published' ? 'secondary' : 'destructive'}
                        className="capitalize"
                      >
                        {solution.status}
                      </Badge>
                    </TableCell>
                    <TableCell>{solution?.Market?.name}</TableCell>
                    <TableCell className="capitalize">
                      {solution?.Tenant?.first_name} {solution?.Tenant?.last_name}
                    </TableCell>
                    <TableCell>{formatHumanReadable(solution.created_at)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>{' '}
        </div>
      </div>
    </>
  );
}

export default SolutionList;
