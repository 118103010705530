import React, { useEffect, useState } from 'react';
import { Button } from '../../../../ui/button';
import { useSelection } from '../../../../../contexts/SelectionContext';

import { Separator } from '../../../../ui/separator';

const DeleteAction = ({ triggerAction, onDelete }) => {
  const [deleteMsg, setDeleteMsg] = useState('');
  const { selectedFiles, setSelectedFiles } = useSelection();

  useEffect(() => {
    setDeleteMsg(() => {
      if (selectedFiles.length === 1) {
        return `Are you sure you want to delete '${selectedFiles[0].name}'?`;
      }

      return `Are you sure you want to delete these ${selectedFiles.length} items?`;
    });
  }, []);

  const handleDeleting = () => {
    onDelete(selectedFiles);
    setSelectedFiles([]);
    triggerAction.close();
  };

  return (
    <div>
      <p className="mb-1 mt-0 break-words p-4 font-semibold text-foreground">{deleteMsg}</p>
      <Separator />
      <div className="mb-3 mr-4 flex justify-end gap-2 py-2">
        <Button variant="secondary" onClick={() => triggerAction.close()}>
          Cancel
        </Button>
        <Button variant="destructive" onClick={handleDeleting}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteAction;
