import { LogOut, User } from 'lucide-react';
import { Avatar } from '../ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { logout } from '../../services/AuthService';
import { DownArrowIcon, LogoutIcon, PersonIcon } from '../../common/icon';

export function NavUser() {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.getSession();

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();
    navigate('/');
  };

  const getFirstLetter = (string) => string.charAt(0).toUpperCase();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center gap-2 px-1 py-1 text-left text-sm">
          <Avatar className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-lightGreen3 bg-lightGreen2">
            <span className="select-none text-xs text-darkGray3">
              {getFirstLetter(user.first_name)}
              {getFirstLetter(user.last_name)}
            </span>
          </Avatar>
          <div className="flex flex-1 select-none items-center gap-1 text-left text-xs capitalize leading-tight text-white">
            <span>{user.first_name}</span>
            <span>{user.last_name}</span>
            <DownArrowIcon size={10} />
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="rounded-md bg-white text-lightBlack3" side={'bottom'} align="end">
        <div className="md:hidden">
          <DropdownMenuLabel className="p-0 font-normal">
            <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
              <Avatar className="flex h-8 w-8 items-center justify-center rounded-full border">
                <span>
                  {getFirstLetter(user.first_name)}
                  {getFirstLetter(user.last_name)}
                </span>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold capitalize">{user.first_name}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
        </div>
        <DropdownMenuGroup className="py-1.5">
          <DropdownMenuItem
            className="cursor-pointer px-3 py-1 pr-6 text-base focus:bg-lightWhite focus:text-black"
            onClick={() => navigate('/profile')}
          >
            <PersonIcon />
            My Account
          </DropdownMenuItem>
          <DropdownMenuItem
            className="cursor-pointer px-3 py-1 pr-6 text-base focus:bg-lightWhite focus:text-black"
            onClick={handleLogout}
          >
            <LogoutIcon />
            Logout
          </DropdownMenuItem>{' '}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
