// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storeModal .modal-dialog {
    max-width: 55rem;
}
.storeModal .modal-content {
    background: #232323;
    color: #fff;
}
.storeModal .modal-header, .storeModal .modal-footer {
    border-color: #777777;
}
.storeModal .modal-header .btn-close {
    filter: invert(1);
}
.storeModal .modal-body .img-thumbnail {
    border-radius: 0;
    padding: 0;
}
.storeModal .modal-body span {
    background-color: #fff;
    width: 100%;
    display: block;
    color: #252525;
    padding: 0.3rem;
    font-size: 12px;
}
.storeModal button.cancel {
    background-color: #535353;
    border-color: #535353;
    color: #fff;
}
.storeModal button.cancel:hover, .storeModal button.cancel:focus {
    background-color: rgb(83 83 83 / 60%);
}
.add-new-btn button:active {
    color: rgba(255, 255, 255, 0.8) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/virtual/solutionlist/thememodal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,qBAAqB;AACzB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,sBAAsB;IACtB,WAAW;IACX,cAAc;IACd,cAAc;IACd,eAAe;IACf,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,WAAW;AACf;AACA;IACI,qCAAqC;AACzC;AACA;IACI,0CAA0C;AAC9C","sourcesContent":[".storeModal .modal-dialog {\n    max-width: 55rem;\n}\n.storeModal .modal-content {\n    background: #232323;\n    color: #fff;\n}\n.storeModal .modal-header, .storeModal .modal-footer {\n    border-color: #777777;\n}\n.storeModal .modal-header .btn-close {\n    filter: invert(1);\n}\n.storeModal .modal-body .img-thumbnail {\n    border-radius: 0;\n    padding: 0;\n}\n.storeModal .modal-body span {\n    background-color: #fff;\n    width: 100%;\n    display: block;\n    color: #252525;\n    padding: 0.3rem;\n    font-size: 12px;\n}\n.storeModal button.cancel {\n    background-color: #535353;\n    border-color: #535353;\n    color: #fff;\n}\n.storeModal button.cancel:hover, .storeModal button.cancel:focus {\n    background-color: rgb(83 83 83 / 60%);\n}\n.add-new-btn button:active {\n    color: rgba(255, 255, 255, 0.8) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
