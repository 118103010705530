import {
  ChevronDown,
  FolderPlus,
  Upload,
  ClipboardPaste,
  File,
  GalleryVerticalEnd,
  Trash2,
  ChevronRight,
} from 'lucide-react';

import { BsBoxArrowLeft, BsFillPersonFill, BsThreeDots } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';

import appIcon from '../assets/images/virtual/appIcon.png';
import productIcon from '../assets/images/virtual/productIcon.png';
import brushIcon from '../assets/images/virtual/brush.png';
import plusIcon from '../assets/images/virtual/plus.svg';
import pickerIcon from '../assets/images/pickerIcon.png';

import virturalStoreLogo from '../assets/images/virturalStoreLogo.png';
import AlertImg from '../assets/images/alert.svg';
import noImageIcon from '../assets/images/no-image-icon.png';

import { storefront, interior, products, apps, others } from '../assets/images/virtual';

const YellowFolder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="512"
    height="512"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 512 512"
    {...props}
  >
    <g>
      <path
        fill="#ffb125"
        d="M448 80H242.2c-8.8 0-16 7.2-16 16 0 2.5.6 5 1.7 7.2.2.4.4.7.6 1.1l41 71.8c5.8 9.9 16.3 16 27.8 16H496c8.8 0 16-7.2 16-16v-32c0-35.4-28.7-64.1-64-64.1"
      ></path>
      <path
        fill="#fcd354"
        d="m512 160-214.8.1-40.7-71.2c-.1-.3-.3-.5-.4-.8-14.3-24.8-40.7-40-69.2-40.1H48C21.5 48 0 69.5 0 96v320c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48z"
      ></path>
    </g>
  </svg>
);

export {
  FolderPlus as NewFolderIcon,
  Upload as UploadIcon,
  ClipboardPaste as PasteIcon,
  ChevronRight as RightArrowIcon,
  FaCaretDown as DownArrowIcon,
  YellowFolder as FolderIcon,
  Trash2 as DeleteIcon,
  File,
  GalleryVerticalEnd,
  appIcon,
  productIcon,
  brushIcon,
  BsBoxArrowLeft as LogoutIcon,
  BsFillPersonFill as PersonIcon,
  plusIcon,
  BsThreeDots as EllipsisIcon,
  virturalStoreLogo,
  AlertImg,
  noImageIcon,
  pickerIcon,
  storefront,
  interior,
  products,
  apps,
  others,
};
