import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

function AuthRoute() {
  const auth = useAuth();

  // If authenticated, redirect to dashboard
  if (auth.isAuth()) {
    return <Navigate to="/solution" replace />;
  }

  // If not authenticated, show the auth pages (login, register, etc.)
  return <Outlet />;
}

export default AuthRoute;
