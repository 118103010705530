import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';

import { useFileNavigation } from '../../../../contexts/FileNavigationContext';
import { ChevronRight, Folder } from 'lucide-react';
import { cn } from '../../../../utils/common';
import { FolderIcon } from '../../../../common/icon';

const FolderTree = ({ folder, handleShowClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { currentPath, setCurrentPath } = useFileNavigation();

  const handleFolderSwitch = () => {
    setIsActive(true);
    setCurrentPath(folder.path);
    handleShowClick(1);
  };

  const handleCollapseChange = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    // Setting isActive to a folder if its path matches currentPath
    setIsActive(currentPath === folder.path);

    // Auto expand parent folder if its child is accessed via file navigation
    // Explanation: Checks if the current folder's parent path matches with any folder path i.e. Folder's parent
    // then expand that parent.
    const currentPathArray = currentPath.split('/');
    // splits with '/' and pops to remove last element to get current folder's parent path
    currentPathArray.pop();
    const currentFolderParentPath = currentPathArray.join('/');
    if (currentFolderParentPath === folder.path) {
      setIsOpen(true);
    }
    //
  }, [currentPath, folder.path]);

  if (folder.subDirectories.length > 0) {
    return (
      <>
        <div
          className={`flex cursor-pointer items-center gap-1 py-2 hover:bg-accent ${isActive ? 'bg-accent' : ''}`}
          onClick={handleFolderSwitch}
        >
          <span
            onClick={handleCollapseChange}
            className={cn('transition-transform duration-300', isOpen ? 'rotate-90' : 'rotate-0')}
          >
            <ChevronRight size={18} />
          </span>
          <div className="flex items-center gap-2 text-xs">
            <FolderIcon className="h-4 w-4" alt="folder" />
            <span title={folder.name}>{folder.name}</span>
          </div>
        </div>
        <Collapsible open={isOpen}>
          <div className="ml-3">
            {folder.subDirectories.map((item, index) => (
              <FolderTree key={index} folder={item} handleShowClick={handleShowClick} />
            ))}
          </div>
        </Collapsible>
      </>
    );
  }

  return (
    <div
      className={`flex cursor-pointer items-center gap-1 p-2 hover:bg-accent ${isActive ? 'bg-accent' : ''}`}
      onClick={handleFolderSwitch}
    >
      <span className="min-w-2.5"></span>
      <div className="flex items-center gap-2 text-xs">
        <FolderIcon className="h-4 w-4" alt="folder" />
        <span title={folder.name}>{folder.name}</span>
      </div>
    </div>
  );
};

export default FolderTree;
