import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import { storeList, setStore } from '../../services/StoreService';

import { Button } from '../../components/ui/button';
import { Label } from '../../components/ui/label';
import { Card, CardContent, CardHeader, CardFooter, CardDescription } from '../../components/ui/card';
import { Loader2 } from 'lucide-react';

import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    store: yup.string().label('Store').required(),
  })
  .required();

const StoreList = () => {
  const title = 'Stores';
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const { getSession } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getStores = async () => {
    const user = getSession();
    if (user && user.tenant_id) {
      const response = await storeList(user.tenant_id);

      // console.log(response);
      if (response.success) {
        if (response.data.length === 1) {
          await setStore(response.data[0]);
          navigate('/solution');
        }
        setStores(response.data);
      }
    }
  };

  const handleStore = async (data) => {
    try {
      setIsLoading(true);
      const result = stores.filter((store) => store.id === data.store);
      const response = await setStore(result);
      setIsLoading(false);
      toast.success(response);
      navigate('/solution');
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <main className="flex min-h-screen items-center justify-center bg-lightWhite px-4 py-8">
        <Card className="my-auto flex w-[25rem] flex-col rounded-md bg-white p-6 shadow-md">
          <CardHeader className="pb-2 text-center">
            <div className="flex justify-center pb-4 pt-4">
              <Link className="block" to="/">
                <img src={logo} className="max-w-[130px] brightness-[0.2]" alt="logo" />
              </Link>
            </div>
            <CardDescription className="!mb-2 text-xl font-semibold text-lightBlack">Select Store</CardDescription>
          </CardHeader>

          <CardContent>
            <form onSubmit={handleSubmit(handleStore)} className="space-y-4" noValidate>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="store">
                  {title}
                </Label>
                <select
                  id="store"
                  className={`w-full rounded-md border bg-white px-3 py-2 text-base text-lightBlack ${errors.market_id ? 'focus:ring-red-500 border-red' : 'border-gray-300 focus:ring-blue-500'}`}
                  {...register('store')}
                >
                  <option value="">Choose one</option>
                  {stores &&
                    stores.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.store_name}
                      </option>
                    ))}
                </select>
                {errors.store && <p className="text-red-500 text-sm">{errors.store.message}</p>}
              </div>

              <Button type="submit" className="mt-4 w-full" disabled={isLoading}>
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Submit
              </Button>
            </form>
          </CardContent>

          <CardFooter className="!mb-4 !mt-6 flex flex-col">
            <div className="text-center text-base">
              <Link to="/login" className="ml-1 text-blue-600 underline underline-offset-2">
                Back
              </Link>
            </div>
          </CardFooter>
        </Card>
      </main>
      <ToastContainer />
    </>
  );
};

export default StoreList;
