import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardDescription } from '../../components/ui/card';

import logo from '../../assets/images/logo.png';
import useAuth from '../../hooks/useAuth';

const VerifyEmail = () => {
  const title = 'VCOM - Verify Eamil';
  const { verifyEmail } = useAuth();
  const token = useParams();
  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    try {
      const response = await verifyEmail(token);
      if (response.success) {
        navigate('/login', { state: { successMessage: response.message } });
      } else {
        navigate('/login', { state: { errorMessage: response.message } });
      }
    } catch (err) {
      navigate('/login', { state: { errorMessage: err.message } });
    }
  };
  useEffect(() => {
    (async () => {
      if (token) {
        await handleVerifyEmail();
      } else {
        navigate('/login', { state: { errorMessage: 'Invalid token.' } });
      }
    })();
  }, [token]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="flex min-h-screen items-center justify-center bg-lightWhite px-4 py-8">
        <Card className="my-auto flex w-[25rem] flex-col rounded-md bg-white p-6 shadow-md">
          <CardHeader className="pb-2 text-center">
            <div className="flex justify-center pb-7 pt-4">
              <Link className="block" to="/">
                <img src={logo} className="max-w-[130px] brightness-[0.2]" alt="logo" />
              </Link>
            </div>

            <CardDescription className="!mb-4 text-xl font-semibold text-lightBlack">
              Verifying your VCOM Account
            </CardDescription>
          </CardHeader>
        </Card>
      </main>
    </>
  );
};

export default VerifyEmail;
