const baseURL = process.env.REACT_APP_API_FUNC_URL;

const createFolder = async (storeId, name, parentId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${baseURL}/FileManagerCreateFolder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storeId, name, parentId }),
        });

        const jsonResponse = await response.json();

        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(new Error(jsonResponse.message));
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const deleteFile = async (storeId, ids) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${baseURL}/FileManagerDeleteItems`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storeId, ids }),
        });

        const jsonResponse = await response.json();

        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(new Error(jsonResponse.message));
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const downloadFile = async (storeId, files) => {
  try {
    const fileQuery = files.map((file) => `files=${encodeURIComponent(file.id)}`).join('&');
    const url = `${baseURL}/FileManagerDownloadItems?storeId=${storeId}${fileQuery}`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    return error;
  }

  return null;
};

const copyFiles = async (storeId, sourceIds, destinationId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${baseURL}/FileManagerCopyItems`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storeId, sourceIds, destinationId }),
        });

        const jsonResponse = await response.json();

        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(new Error(jsonResponse.message));
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const moveFiles = async (storeId, sourceIds, destinationId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${baseURL}/FileManagerMoveItems`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storeId, sourceIds, destinationId }),
        });

        const jsonResponse = await response.json();

        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(new Error(jsonResponse.message));
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const getAllFiles = async (storeId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${baseURL}/FileManagerGetItems?storeId=${storeId}`, { method: 'GET' });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(new Error(jsonResponse.message));
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const renameFile = async (storeId, id, newName) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${baseURL}/FileManagerRenameItem`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storeId, id, newName }),
        });

        const jsonResponse = await response.json();

        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(new Error(jsonResponse.message));
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

export { createFolder, deleteFile, downloadFile, copyFiles, moveFiles, getAllFiles, renameFile };
