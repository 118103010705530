// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-modal {
  margin-top: auto;
  border: 1px solid #111111;
  border-radius: 5px;
  width: fit-content;
  overflow-x: hidden;
  padding: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.253);
  background-color: #343536;
}

.close-icon {
  padding: 5px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: #6a6a6a;
  }
}

.fm-modal-header {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(151, 151, 151);
  padding: 0.3rem 1rem;

  .fm-modal-heading {
    margin: 0;
    font-weight: bold;
  }
}

.dialog[open] {
  animation: expand 0.4s forwards;

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
}

@keyframes expand {
  from {
    transform: scale(0.4);
  }

  to {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,2CAA2C;EAC3C,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,kBAAkB;;EAElB;IACE,eAAe;IACf,yBAAyB;EAC3B;AACF;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2CAA2C;EAC3C,oBAAoB;;EAEpB;IACE,SAAS;IACT,iBAAiB;EACnB;AACF;;AAEA;EACE,+BAA+B;;EAE/B;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".fm-modal {\n  margin-top: auto;\n  border: 1px solid #111111;\n  border-radius: 5px;\n  width: fit-content;\n  overflow-x: hidden;\n  padding: 0;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.253);\n  background-color: #343536;\n}\n\n.close-icon {\n  padding: 5px;\n  border-radius: 50%;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #6a6a6a;\n  }\n}\n\n.fm-modal-header {\n  color: white;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid rgb(151, 151, 151);\n  padding: 0.3rem 1rem;\n\n  .fm-modal-heading {\n    margin: 0;\n    font-weight: bold;\n  }\n}\n\n.dialog[open] {\n  animation: expand 0.4s forwards;\n\n  &::backdrop {\n    background: rgba(0, 0, 0, 0.5);\n  }\n}\n\n@keyframes expand {\n  from {\n    transform: scale(0.4);\n  }\n\n  to {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
