import FileList from '../Explorer/FileList/FileList';
import BreadCrumb from '../Explorer/BreadCrumb/BreadCrumb';
import Toolbar from '../Explorer/Toolbar/Toolbar';

const ContentList = ({
  setDraggedItem,
  contentSelected,
  triggerAction,
  onCreateFolder,
  onRename,
  onFileOpen,
  onLayoutChange,
  onRefresh,
  enableFilePreview,
}) => (
  <div className="flex h-full flex-col gap-0.5 bg-darkGray">
    <Toolbar
      onLayoutChange={onLayoutChange}
      onRefresh={onRefresh}
      triggerAction={triggerAction}
      allowCreateFolder
      allowUploadFile
    />
    <div className="h-full bg-lightGray3">
      <BreadCrumb />
      <FileList
        onCreateFolder={onCreateFolder}
        onRename={onRename}
        onFileOpen={onFileOpen}
        onRefresh={onRefresh}
        enableFilePreview={enableFilePreview}
        triggerAction={triggerAction}
        setDraggedItem={setDraggedItem}
      />
    </div>
  </div>
);

export default ContentList;
