import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Card, CardContent, CardHeader, CardFooter, CardDescription } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';

import useAuth from '../../hooks/useAuth';
import { Loader2 } from 'lucide-react';
import { cn } from '../../utils/common';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    password: yup
      .string()
      .label('Password')
      .required()
      .matches(
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'The password you entered does not meet our password criteria. Please enter another.'
      ),
    password_confirmation: yup.string().label('Confirm Password').required(),
    // .oneOf([yup.ref('password')], 'Passwords must match'), // need to be tested
    token: yup.string().optional(),
  })
  .required();

const ResetPassword = () => {
  const title = 'VCOM - Reset Password';
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.errorMessage || '');
  const { sendResetPassword } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleResetPassword = async (data) => {
    try {
      setIsLoading(true);
      const response = await sendResetPassword(data);
      if (response.success) {
        setIsLoading(false);
        navigate('/login', { state: { successMessage: response.message } });
      } else {
        setMessage(response.message);
        setIsLoading(false);
      }
    } catch (err) {
      setMessage(err.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (token) {
      setValue('token', token);
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="flex min-h-screen items-center justify-center bg-lightWhite px-4 py-8">
        <Card lassName="my-auto flex w-[25rem] flex-col rounded-md bg-white p-6 shadow-md">
          <CardHeader className="pb-2 text-center">
            <div className="flex justify-center pb-7 pt-4">
              <Link className="block" to="/">
                <img src={logo} className="max-w-[130px] brightness-[0.2]" alt="logo" />
              </Link>
            </div>
            <CardDescription className="!mb-4 text-xl font-semibold text-lightBlack">
              Set a new password
            </CardDescription>
            {message && (
              <Alert variant="danger" className="!my-2">
                <AlertDescription dangerouslySetInnerHTML={{ __html: message }}></AlertDescription>
              </Alert>
            )}
          </CardHeader>

          <CardContent>
            <form onSubmit={handleSubmit(handleResetPassword)} className="space-y-4" noValidate>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="password">
                  Password
                </Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  {...register('password')}
                  className={cn(
                    errors.password ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.password && <p className="text-sm text-red">{errors.password.message}</p>}
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Confirm Password</Label>
                <Input
                  id="password_confirmation"
                  type="password"
                  placeholder="Enter Confirmation Password"
                  {...register('password_confirmation')}
                  className={cn(
                    errors.password_confirmation ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.password_confirmation && (
                  <p className="text-sm text-red">{errors.password_confirmation.message}</p>
                )}
              </div>
              <Input type="hidden" {...register('token')} />
              <Button type="submit" className="mt-4 w-full" disabled={isLoading}>
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Set New Password
              </Button>
            </form>
          </CardContent>

          <CardFooter className="!mb-4 !mt-6 flex flex-col">
            <div className="text-center text-base">
              Back to
              <Link to="/login" className="ml-1 text-blue-600 underline underline-offset-2">
                Login
              </Link>
            </div>
          </CardFooter>
        </Card>
      </main>
    </>
  );
};

export default ResetPassword;
