import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatHumanReadable = (dateString) => {
  const date = dayjs(dateString);
  const now = dayjs();

  // Today
  if (date.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
    return `Today at ${date.format('h:mm A')}`;
  }

  // Yesterday
  if (date.format('YYYY-MM-DD') === now.subtract(1, 'day').format('YYYY-MM-DD')) {
    return `Yesterday at ${date.format('h:mm A')}`;
  }

  // Within last week
  if (now.diff(date, 'day') < 7) {
    return `${date.format('dddd')} at ${date.format('h:mm A')}`; // e.g., "Tuesday at 2:30 PM"
  }

  // This year
  if (date.format('YYYY') === now.format('YYYY')) {
    return date.format('MMMM D at h:mm A'); // e.g., "February 22 at 12:23 PM"
  }

  // Different year
  return date.format('MMMM D, YYYY at h:mm A'); // e.g., "February 22, 2025 at 12:23 PM"
};
