import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Home } from 'lucide-react';

function NotFound() {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>404 - Not Found</title>
      </Helmet>

      <main className="flex min-h-screen items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="mb-4 text-6xl font-bold text-gray-800">404</h1>
          <p className="mb-8 text-2xl font-semibold text-gray-600">Oops! Page not found</p>
          <p className="mb-8 text-gray-500">The page you&apos;re looking for doesn&apos;t exist or has been moved.</p>
          <Link
            to="/"
            className="inline-flex items-center rounded-md bg-blue-600 px-4 py-2 text-white transition-colors duration-300 hover:bg-blue-700"
          >
            <Home className="mr-2 h-5 w-5" />
            Go back home
          </Link>
        </div>
      </main>
    </>
  );
}

export default NotFound;
