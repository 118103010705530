import storefront from './object/storefront.png';
import entrancefloor from './object/entrancefloor.png';
import albamodernchair from './object/ALBA MODERN CHAIR.png';
import apps from './object/Apps.png';
import products from './object/Products.png';
import avatars from './object/Avatars.png';
import tablet from './object/tablet.png';
import chair from './object/Chair.png';
import chair2 from './object/Chair2.png';
import productstandlarge from './object/productstandlarge.png';
import floorlamp from './object/floorlamp.png';
import interior from './object/interior.png';
import others from './object/others.png';
import folderIcon from './object/folderIcon.png';

export {
  storefront,
  entrancefloor,
  albamodernchair,
  apps,
  products,
  avatars,
  tablet,
  chair,
  chair2,
  productstandlarge,
  floorlamp,
  interior,
  others,
  folderIcon,
};

// export { default as Background } from './bg/background.jpg';
// export { default as First } from './photos/first.jpg';
