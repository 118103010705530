import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSidebar } from '../ui/sidebar';
import { NavUser } from './NavUser';

import { getStoreSession } from '../../services/StoreService';
import { AlertImg, virturalStoreLogo } from '../../common/icon';

export const Header = () => {
  const { toggleSidebar } = useSidebar();
  const store = getStoreSession();

  return (
    <header className="sticky top-0 z-50 flex w-full items-center bg-darkGray shadow-lg">
      <div className="flex h-[--header-height] w-full items-center justify-between gap-2 px-2">
        <div className="flex items-center gap-2">
          <Link className="block" to="/">
            <img src={virturalStoreLogo} className="max-w-[130px]" alt="logo" />
          </Link>
        </div>
        <div className="h-full md:ml-10">
          <ul className="flex h-full flex-nowrap items-end gap-6 whitespace-nowrap">
            <li>
              <NavLink
                className="block border-b-[3px] border-lightBlue2 px-[3px] py-[10px] text-base font-medium leading-4 tracking-[0.5px] text-white transition duration-300 hover:bg-gradient-to-b hover:from-darkGray hover:to-darkGray2"
                to={'#'}
              >
                Commerce
              </NavLink>
            </li>
            <li>
              <NavLink
                className="block border-b-[3px] border-pink px-[3px] py-[10px] text-base font-medium leading-4 tracking-[0.5px] text-white transition duration-300 hover:bg-gradient-to-b hover:from-darkGray hover:to-darkGray2"
                to={'/solution'}
              >
                Store Designer
              </NavLink>
            </li>
          </ul>
        </div>
        <div class="hidden w-1/2 md:block md:w-1/2 lg:pl-44">
          <h3 class="text-base font-medium text-lightGray">Merchant Admin Portal</h3>
        </div>
        <div className="flex items-center gap-2">
          <NavUser />
        </div>
      </div>

      {store.Subscription && [2, 5, 6, 8, 9].includes(store.Subscription?.subscription_reason) && (
        <div className="text-center">
          <div className="bg-green-100 border-green-400 text-green-700 mb-0 inline-flex w-full justify-center rounded border px-4 py-3 text-sm">
            <img src={AlertImg} className="mr-2 h-5 w-5" alt="alert" />
            {store.Subscription?.subscription_reason === 2 && (
              <span>
                Free Trial has ended.{' '}
                <Link to="/merchant/subscription/plan/list" className="text-blue-600 underline">
                  Click here
                </Link>{' '}
                and take action to reveal any restrictions.
              </span>
            )}
            {[5, 6, 8].includes(store.Subscription?.subscription_reason) && (
              <span>
                Store is deactivated.{' '}
                <Link to="/merchant/subscription/plan/list" className="text-blue-600 underline">
                  Click here
                </Link>{' '}
                and take action to reveal any restrictions.
              </span>
            )}
            {store.Subscription?.subscription_reason === 9 && (
              <span>
                Store is archived due to inactivity and will be deleted in xx days.{' '}
                <Link to="/merchant/subscription/plan/list" className="text-blue-600 underline">
                  Click here
                </Link>{' '}
                to activate store.
              </span>
            )}
          </div>
        </div>
      )}
    </header>
  );
};
