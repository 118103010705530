import { File, GalleryVerticalEnd } from './icon';

export const navMain = [
  {
    title: 'Solutions',
    url: '/solution',
    icon: <i className="bi bi-pencil-square"></i>,
    isActive: false,
  },
  {
    title: 'Assets',
    url: '/solution',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        focusable="false"
        aria-hidden="true"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        className="fill-current"
      >
        <path
          d="M20.174,42.281L7.837,29.944c-0.657-0.657-1.722-0.657-2.379,0c-0.657,0.657-0.657,1.722,0,2.379
    l12.337,12.336c0.328,0.329,0.759,0.493,1.188,0.493c0.431,0,0.862-0.164,1.19-0.493C20.831,44.003,20.831,42.938,20.174,42.281"
        />
        <path
          d="M45.007,21.015L43.06,8.687c-0.151-0.959-0.905-1.713-1.864-1.866l-12.33-1.945
    c-0.707-0.113-1.428,0.12-1.935,0.629L8.706,23.729c-0.875,0.877-0.875,2.297,0,3.172L22.98,41.175
    c0.876,0.876,2.296,0.876,3.172,0l18.224-18.224C44.885,22.444,45.118,21.723,45.007,21.015 M38.559,14.733
    c-0.876,0.874-2.296,0.874-3.172,0c-0.876-0.876-0.876-2.296,0-3.173c0.876-0.876,2.296-0.876,3.172,0
    C39.435,12.437,39.435,13.857,38.559,14.733"
        />
      </svg>
    ),
    isActive: false,
  },
];
