import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import { Loader2 } from 'lucide-react';

import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Card, CardContent, CardHeader, CardFooter, CardDescription } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { Checkbox } from '../../components/ui/checkbox';
import { cn } from '../../utils/common';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    company_name: yup.string().label('Organization name').required(),
    first_name: yup.string().label('First name').required(),
    last_name: yup.string().label('Last name').required(),
    email: yup.string().email().label('Email').required(),
    password: yup
      .string()
      .label('Password')
      .required()
      .matches(
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'The password you entered does not meet our password criteria. Please enter another.'
      ),
    c_password: yup
      .string()
      .required('Please confirm your password.')
      .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
    tcond: yup.bool().label('Terms and Condition').oneOf([true]).required('You must accept the terms and conditions'),
  })
  .required();

const Signup = () => {
  const title = 'VCOM - Signup';
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.errorMessage || '');
  const { addUser } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tcond: false,
    },
  });

  const handleSignup = async (data) => {
    try {
      setIsLoading(true);
      const response = await addUser(data);
      if (response.success) {
        setIsLoading(false);
        navigate('/login', { state: { successMessage: response.message } });
      } else {
        setIsLoading(false);
        setMessage(response.message);
      }
    } catch (err) {
      setIsLoading(false);
      setMessage(err.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="flex min-h-screen items-center justify-center bg-lightWhite px-4 py-8">
        <Card className="my-auto flex w-[25rem] flex-col rounded-md bg-white p-6 shadow-md">
          <CardHeader className="pb-2 text-center">
            <div className="flex justify-center pb-4 pt-4">
              <Link className="block" to="/">
                <img src={logo} className="max-w-[130px] brightness-[0.2]" alt="logo" />
              </Link>
            </div>
            <CardDescription className="!mb-4 text-xl font-semibold text-lightBlack">
              Create Your VCOM Account
            </CardDescription>

            {message && (
              <Alert variant="danger" className="!my-2">
                <AlertDescription dangerouslySetInnerHTML={{ __html: message }}></AlertDescription>
              </Alert>
            )}
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(handleSignup)} className="space-y-4" noValidate>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="organization_name">
                  Organization Name
                </Label>
                <Input
                  id="organization_name"
                  type="text"
                  placeholder="Enter Company Name"
                  {...register('company_name')}
                  className={cn(
                    errors.company_name ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.company_name && <p className="text-red-500 text-sm">{errors.company_name.message}</p>}
              </div>{' '}
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="first_name">
                  First Name
                </Label>
                <Input
                  id="first_name"
                  type="text"
                  placeholder="Enter first name"
                  {...register('first_name')}
                  className={cn(
                    errors.first_name ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name.message}</p>}
              </div>{' '}
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="last_name">
                  Last Name
                </Label>
                <Input
                  id="last_name"
                  type="text"
                  placeholder="Enter last name"
                  {...register('last_name')}
                  className={cn(
                    errors.last_name ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name.message}</p>}
              </div>{' '}
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="email">
                  Email
                </Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  {...register('email')}
                  className={cn(
                    errors.email ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="password">
                  Password
                </Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  {...register('password')}
                  className={cn(
                    errors.password ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
              </div>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="password">
                  Confirm Password
                </Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Confirmation Password"
                  {...register('c_password')}
                  className={cn(
                    errors.c_password ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.c_password && <p className="text-red-500 text-sm">{errors.c_password.message}</p>}
              </div>
              <div className="my-3 space-y-1">
                <div className="flex items-start">
                  <Controller
                    name="tcond"
                    control={control}
                    rules={{
                      required: 'You must agree to the Terms of Service and Privacy Policy',
                    }}
                    render={({ field }) => (
                      <Checkbox
                        id="tcond"
                        className="mr-2 mt-1"
                        error={errors.tcond}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                  <Label htmlFor="tcond" className={`text-[13px] ${errors.tcond ? 'text-red' : 'text-lightBlack/70'}`}>
                    By creating an account, you agree to our Terms of Service and have read and understood the Privacy
                    Policy.
                  </Label>
                </div>
                {errors.tcond && <p className="text-red-500 text-sm">{errors.tcond.message}</p>}
              </div>
              <Button type="submit" className="mt-4 w-full" disabled={isLoading}>
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Register
              </Button>
            </form>
          </CardContent>
          <CardFooter className="flex flex-col">
            <div className="mt-4 text-center text-base">
              Already registered?
              <Link to="/login" className="ml-1 text-blue-600 underline underline-offset-2">
                Login here.
              </Link>
            </div>
          </CardFooter>
        </Card>
      </main>
    </>
  );
};

export default Signup;
