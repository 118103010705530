import { useEffect, useState } from 'react';
import { Loader2, X } from 'lucide-react';

import { objectDelete } from '../../../../services/SolutionService';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../ui/dialog';
import { Separator } from '../../../ui/separator';
import { Button } from '../../../ui/button';
import { toast } from 'sonner';

const DeleteObjectModal = ({ showDeleteModal, handleShowDelete, selectedId }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveObject = async () => {
    try {
      setIsLoading(true);
      const response = await objectDelete({ id: selectedId });
      if (response.success) {
        setIsLoading(false);
        handleShowDelete();
        toast.success(response.message);
      } else {
        setIsLoading(false);
        handleShowDelete();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowDelete();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setShow(showDeleteModal);
  }, [showDeleteModal]);

  return (
    <Dialog open={show} onOpenChange={handleShowDelete}>
      <DialogContent className="max-w-ml h-fit bg-white">
        <DialogHeader className="!mb-0 flex items-center pt-1">
          <div className="flex h-full w-full items-center justify-between p-4 pb-5 text-lightBlack3">
            <DialogTitle className="text-xl font-medium">Delete Object</DialogTitle>
            <DialogClose>
              <X size={28} strokeWidth={2} className="text-black/70" />
            </DialogClose>
          </div>
        </DialogHeader>
        <Separator className="bg-lightGray2" />
        <div className="flex gap-2 p-4 pb-8 text-lightBlack3">
          <p>Are you sure that you want to remove this item from the canvas?</p>
        </div>
        <Separator className="bg-lightGray2" />
        <DialogFooter className="p-4">
          <DialogClose asChild>
            <Button
              type="button"
              variant="secondary"
              className="h-[38px] w-[47px] rounded-md bg-lightBlue3 text-base font-normal capitalize tracking-[0px] hover:bg-lightBlue4"
            >
              No
            </Button>
          </DialogClose>
          <Button
            type="button"
            className="h-[38px] w-[62px] rounded-md bg-lightBlue3 text-base font-normal capitalize tracking-[0px] hover:bg-lightBlue4"
            variant="secondary"
            disabled={isLoading}
            onClick={handleRemoveObject}
          >
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteObjectModal;
