const marketList = async (storeId = null) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/marketlist?store_id=${storeId}`);
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const themeList = async () =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/VRThemeList`);
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const themeStyleList = async (themeId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/VRThemeStyleList?theme_id=${themeId}`);
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const solutionList = async (storeId = null) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/SolutionList?store_id=${storeId}`);
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const solutionDetail = async (solutionId = null) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/VRAppSolutionViewFull?id=${solutionId}`);
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const solutionCreate = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/SolutionCreate`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const solutionUpdate = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/SolutionUpdate`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const solutionDelete = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/SolutionDelete`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const objectCreate = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/objectcreate`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const objectDelete = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/objectdelete`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const customizationCreate = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/customizationcreate`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

export {
  marketList,
  themeList,
  themeStyleList,
  solutionList,
  solutionDetail,
  solutionCreate,
  solutionUpdate,
  solutionDelete,
  objectCreate,
  objectDelete,
  customizationCreate,
};
