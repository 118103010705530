// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-progress {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .fm-progress-bar {
    width: 100%;
    border-radius: 5px;
    background-color: #c6c6c6;
    height: 5px;

    .fm-progress-bar-fill {
      height: 100%;
      background-color: #6155b4;
      border-radius: 5px;
      max-width: 100%;
    }
  }

  .fm-progress-status {
    font-size: 0.75em;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  .fm-upload-canceled {
    font-size: 0.75em;
    font-weight: 600;
    color: rgb(255, 76, 76);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Progress/Progress.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;;EAER;IACE,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;;IAEX;MACE,YAAY;MACZ,yBAAyB;MACzB,kBAAkB;MAClB,eAAe;IACjB;EACF;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;EACzB;AACF","sourcesContent":[".fm-progress {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n\n  .fm-progress-bar {\n    width: 100%;\n    border-radius: 5px;\n    background-color: #c6c6c6;\n    height: 5px;\n\n    .fm-progress-bar-fill {\n      height: 100%;\n      background-color: #6155b4;\n      border-radius: 5px;\n      max-width: 100%;\n    }\n  }\n\n  .fm-progress-status {\n    font-size: 0.75em;\n    font-weight: 600;\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .fm-upload-canceled {\n    font-size: 0.75em;\n    font-weight: 600;\n    color: rgb(255, 76, 76);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
