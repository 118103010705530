// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-checkbox {
    accent-color: white;

    &:disabled {
        cursor: default !important;
    }

    &:hover {
        cursor: pointer;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB;QACI,0BAA0B;IAC9B;;IAEA;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".fm-checkbox {\n    accent-color: white;\n\n    &:disabled {\n        cursor: default !important;\n    }\n\n    &:hover {\n        cursor: pointer;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
