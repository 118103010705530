import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import Moment from 'react-moment';

import { getStoreSession } from '../../../services/StoreService';
import productList from '../../../services/ProductService';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import { Label } from '../../ui/label';
import { SidebarInput } from '../../ui/sidebar';
import { Search } from 'lucide-react';
import { Badge } from '../../ui/badge';

const ProductList = ({ setDraggedItem }) => {
  const [selectedSearchText, setSelectedSearchText] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedProductStatus, setSelectedProductStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productStatus, setProductStatus] = useState([]);
  const store = getStoreSession();

  const getProducts = async () => {
    try {
      let response = null;
      if (selectedProductStatus) {
        response = await productList(store.id, selectedProductStatus);
      } else {
        response = await productList(store.id);
      }
      if (response.success) {
        setProducts(response.data);
      } else {
        setProducts([]);
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const getVendors = () => {
    const uniqueVendors = products
      .map((product) => product.vendor)
      .filter((value, index, self) => self.indexOf(value) === index);
    setVendors(uniqueVendors);
  };

  const getProductTypes = () => {
    const uniqueProductTypes = products
      .map((product) => product.product_type)
      .filter((value, index, self) => self.indexOf(value) === index);
    setProductTypes(uniqueProductTypes);
  };

  const getProductStatus = () => {
    setProductStatus([
      { id: 1, name: 'Draft' },
      { id: 2, name: 'Active' },
      { id: 3, name: 'Archive' },
    ]);
  };

  const getFilteredProducts = () => {
    if (selectedVendor || selectedProductType || selectedSearchText) {
      if (selectedVendor && selectedProductType && selectedSearchText) {
        const filterProds = products.filter(
          (product) =>
            product.vendor === selectedVendor &&
            product.product_type === selectedProductType &&
            product.name.toLowerCase().includes(selectedSearchText.toLowerCase())
        );
        setFilterProducts(filterProds);
      } else if (selectedVendor && selectedProductType) {
        const filterProds = products.filter(
          (product) => product.vendor === selectedVendor && product.product_type === selectedProductType
        );
        setFilterProducts(filterProds);
      } else if (selectedVendor && selectedSearchText) {
        const filterProds = products.filter(
          (product) =>
            product.vendor === selectedVendor && product.name.toLowerCase().includes(selectedSearchText.toLowerCase())
        );
        setFilterProducts(filterProds);
      } else if (selectedProductType && selectedSearchText) {
        const filterProds = products.filter(
          (product) =>
            product.product_type === selectedProductType &&
            product.name.toLowerCase().includes(selectedSearchText.toLowerCase())
        );
        setFilterProducts(filterProds);
      } else if (selectedVendor) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor);
        setFilterProducts(filterProds);
      } else if (selectedProductType) {
        const filterProds = products.filter((product) => product.product_type === selectedProductType);
        setFilterProducts(filterProds);
      } else if (selectedSearchText) {
        const filterProds = products.filter((product) =>
          product.name.toLowerCase().includes(selectedSearchText.toLowerCase())
        );
        setFilterProducts(filterProds);
      }
    } else {
      setFilterProducts(products);
    }
  };

  const getProductStatusBadge = (status) => {
    let badge = (
      <Badge variant="secondary" className="text-[11px]">
        Active
      </Badge>
    );
    if (status === 2) {
      badge = (
        <Badge variant="secondary" className="text-[11px]">
          Active
        </Badge>
      );
    } else if (status === 3) {
      badge = (
        <Badge variant="destructive" className="text-[11px]">
          Archived
        </Badge>
      );
    } else {
      badge = (
        <Badge variant="outline" className="text-[11px]">
          Draft
        </Badge>
      );
    }
    return badge;
  };

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
  }, [selectedProductStatus]);

  useEffect(() => {
    getProductStatus();
    getVendors();
    getProductTypes();
    getFilteredProducts();
  }, [products]);

  useEffect(() => {
    if (products) {
      getFilteredProducts();
    }
  }, [selectedProductType, selectedVendor, selectedSearchText]);

  const handleItemDragStart = (e, item) => {
    const data = {
      ...item,
      isProduct: true,
    };
    e.dataTransfer.setData('application/json', JSON.stringify(data));
  };

  return (
    <div className="flex h-full flex-col gap-0.5 bg-darkGray">
      <div className="flex justify-between bg-muted">
        <div className="flex w-1/2 items-center">
          <h6 className="text-nowrap bg-lightGray3 px-2 py-3 text-xs">Products</h6>
          <div className="relative mr-4 w-full">
            <SidebarInput
              id="search"
              placeholder="Search Products..."
              className="h-[29.6px] rounded-[3px] border-lightGray5 bg-lightGray4 pl-7 text-xs"
              onChange={(e) => setSelectedSearchText(e.target.value)}
              value={selectedSearchText}
            />

            <i className="bi bi-search pointer-events-none absolute left-2 top-1/2 z-50 -translate-y-1/2 cursor-pointer text-lightGray10 hover:text-red"></i>
          </div>
        </div>

        <div className="flex items-center px-2">
          <select
            id="market"
            className={`h-[29.6px] min-w-[167px] rounded-[3px] rounded-br-[0px] rounded-tr-[0px] border border-lightGray5 bg-lightGray4 px-3 py-1.5 text-[12px] text-xs`}
            onChange={(e) => setSelectedProductType(e.target.value)}
            value={selectedProductType}
          >
            {' '}
            <option value="">Type</option>
            {productTypes &&
              productTypes.map((productType) => (
                <option key={productType} value={productType}>
                  {productType}
                </option>
              ))}
          </select>
          <select
            id="status"
            className={`h-[29.6px] min-w-[167px] rounded-none border border-x-0 border-lightGray5 bg-lightGray4 px-3 py-1.5 text-[12px] text-xs`}
            onChange={(e) => setSelectedProductStatus(e.target.value)}
            value={selectedProductStatus}
          >
            {' '}
            <option value="">Status</option>
            {productStatus &&
              productStatus.map((productSts) => (
                <option key={productSts.id} value={productSts.id}>
                  {productSts.name}
                </option>
              ))}
          </select>
          <select
            id="vendor"
            className={`h-[29.6px] min-w-[167px] rounded-[3px] rounded-bl-[0px] rounded-tl-[0px] border border-opacity-30 bg-lightGray4 px-3 py-1.5 text-xs`}
            onChange={(e) => setSelectedVendor(e.target.value)}
            value={selectedVendor}
          >
            {' '}
            <option value="">Vendor</option>
            {vendors &&
              vendors.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="h-full overflow-y-auto bg-lightGray3 p-1 pb-20">
        <Table className="rounded-sm bg-darkGray text-xs">
          <TableHeader>
            <TableRow className="border-lightGray5/30">
              <TableHead>3D Model</TableHead>
              <TableHead>Product</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Vendor</TableHead>
              <TableHead>Last Synchronized</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {filterProducts &&
              filterProducts.map((eachProduct) => (
                <TableRow
                  className="border-lightGray5/30 text-[11px]"
                  key={eachProduct.id}
                  onDragStart={(e) => handleItemDragStart(e, eachProduct)}
                >
                  <TableCell>
                    <Image
                      className="rounded-sm border border-white/70"
                      src={eachProduct.Media?.[0]?.file_url}
                      thumbnail
                      width={50}
                      height={50}
                      alt="product"
                      draggable={true}
                      onDragStart={(e) => {
                        setDraggedItem({
                          id: eachProduct.id,
                          name: eachProduct.name,
                          src: eachProduct.Media?.[0]?.file_url,
                          isProduct: true,
                          height: 100,
                          width: 100,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>{eachProduct.name}</TableCell>
                  <TableCell>{eachProduct.product_type}</TableCell>
                  <TableCell>{getProductStatusBadge(eachProduct.product_status)}</TableCell>
                  <TableCell>{eachProduct.vendor}</TableCell>
                  <TableCell>
                    <Moment format="DD-MM-YYYY HH:mm A">{eachProduct.created_at}</Moment>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ProductList;
