const AppList = () => (
  <div className="flex h-full flex-col gap-0.5">
    <div className="flex items-center bg-muted">
      <h6 className="text-nowrap bg-lightGray3 px-2 py-3 text-xs">Apps</h6>
    </div>
    <div className="flex h-full items-center justify-center bg-lightGray3 text-xs">
      <p>Show Apps here</p>
    </div>
  </div>
);

export default AppList;
