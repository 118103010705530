import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const SolutionViewer = () => {
  const { solutionId } = useParams();

  useEffect(() => {
    console.log('Getting solution id here: ', solutionId);
  }, [solutionId]);

  return (
    <div className="min-h-screen bg-gray-50 px-4 py-8 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <div className="rounded-lg bg-white p-6 shadow-sm">
          <h1 className="mb-4 text-3xl font-bold text-gray-900">Solution #{solutionId}</h1>
          <div className="border-t border-gray-200 pt-4">
            {/* Add your solution content here */}
            <p className="text-gray-600">Solution details will be displayed here...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionViewer;
