import { BiRename } from 'react-icons/bi';
import LayoutToggler from './LayoutToggler';
import { useFileNavigation } from '../../../../contexts/FileNavigationContext';
import { useSelection } from '../../../../contexts/SelectionContext';
import { useClipBoard } from '../../../../contexts/ClipboardContext';
import validateApiCallback from '../../../../utils/validateApiCallback';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';

import { Copy, Download, RefreshCcw, Scissors, Trash2, X } from 'lucide-react';
import { Separator } from '../../../ui/separator';
import { NewFolderIcon, UploadIcon, PasteIcon } from '../../../../common/icon';

const Toolbar = ({ allowCreateFolder = true, allowUploadFile = true, onLayoutChange, onRefresh, triggerAction }) => {
  const { currentFolder } = useFileNavigation();
  const { selectedFiles, setSelectedFiles, handleDownload } = useSelection();
  const { clipBoard, setClipBoard, handleCutCopy, handlePasting } = useClipBoard();

  const toolbarLeftItems = [
    {
      icon: <NewFolderIcon size={18} />,
      text: 'New folder',
      permission: allowCreateFolder,
      onClick: () => triggerAction.show('createFolder'),
    },
    {
      icon: <UploadIcon size={18} />,
      text: 'Upload',
      permission: allowUploadFile,
      onClick: () => triggerAction.show('uploadFile'),
    },
    {
      icon: <PasteIcon size={18} />,
      text: 'Paste',
      permission: !!clipBoard,
      onClick: handleFilePasting,
    },
  ];

  function handleFilePasting() {
    handlePasting(currentFolder);
  }

  const handleDownloadItems = () => {
    handleDownload();
    setSelectedFiles([]);
  };

  // Selected File/Folder Actions
  if (selectedFiles.length > 0) {
    return (
      <div className="flex items-center bg-lightGray3 pt-1.5">
        <div className="flex w-full justify-between">
          <div className="flex gap-1">
            <button
              className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
              onClick={() => handleCutCopy(true)}
            >
              <Scissors size={18} />
              <span>Cut</span>
            </button>
            <button
              className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
              onClick={() => handleCutCopy(false)}
            >
              <Copy size={18} />
              <span>Copy</span>
            </button>
            {clipBoard?.files?.length > 0 && (
              <button
                className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
                onClick={handleFilePasting}
                // disabled={!clipBoard}
              >
                <Copy size={18} />
                <span>Paste</span>
              </button>
            )}
            {selectedFiles.length === 1 && (
              <button
                className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
                onClick={() => triggerAction.show('rename')}
              >
                <BiRename size={18} />
                <span>Rename</span>
              </button>
            )}
            {!selectedFiles.isDirectory && (
              <button
                className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
                onClick={handleDownloadItems}
              >
                <Download size={18} />
                <span>Download</span>
              </button>
            )}
            <button
              className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
              onClick={() => triggerAction.show('delete')}
            >
              <Trash2 size={18} />
              <span>Delete</span>
            </button>
          </div>
          <button
            className="flex w-fit items-center gap-1.5 bg-transparent px-2 py-2 text-xs hover:bg-accent"
            title="Clear selection"
            onClick={() => setSelectedFiles([])}
          >
            <span>
              {selectedFiles.length} item{selectedFiles.length > 1 && 's'} selected
            </span>
            <X size={18} />
          </button>
        </div>
      </div>
    );
  }
  //

  return (
    <div className="flex items-center bg-muted px-4 py-0.5">
      <div className="flex h-9 w-full justify-between">
        <div className="relative flex gap-2">
          {toolbarLeftItems
            .filter((item) => item.permission)
            .map((item, index) => (
              <button
                className="flex items-center gap-1.5 rounded-md p-2 text-xs hover:bg-accent"
                key={index}
                onClick={item.onClick}
              >
                {item.icon}
                <span className="text-xs">{item.text}</span>
              </button>
            ))}
        </div>
        <div className="relative flex items-center gap-1">
          <LayoutToggler onLayoutChange={onLayoutChange} />
          <Separator orientation="verticle" />
          <RefreshButton onRefresh={onRefresh} validateApiCallback={validateApiCallback} setClipBoard={setClipBoard} />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;

const RefreshButton = ({ onRefresh, validateApiCallback, setClipBoard }) => {
  return (
    <div
      className="rounded-md p-1.5 hover:bg-accent"
      onClick={() => {
        validateApiCallback(onRefresh, 'onRefresh');
        setClipBoard(null);
      }}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="flex items-center">
            <RefreshCcw size={18} />
          </TooltipTrigger>
          <TooltipContent side="bottom">
            <p>Refresh</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
