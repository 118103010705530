import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Layouts
import AuthRoute from './components/AuthRoute';
import ProtectedRoute from './components/ProtectedRoute';

// Auth
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ResendVerifyEmail from './pages/auth/ResendVerifyEmail';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import StoreList from './pages/auth/StoreList';
import VerifyEmail from './pages/auth/VerifyEmail';

// Protected Route
import Profile from './pages/protected/Profile';
import SolutionList from './pages/protected/SolutionList';
import SolutionEdit from './pages/protected/SolutionEdit';

import SolutionEdit2 from './pages/Merchant/virtual/SolutionEdit';

//Public Route
import SolutionViewer from './pages/shared/SolutionViewer';
import NotFound from './pages/NotFound';

import AuthProvider from './hooks/AuthProvider';
import { ThemeProvider } from './contexts/ThemeProvider';
import { SidebarProvider } from './components/ui/sidebar';

import { Toaster } from './components/ui/sonner';

function App() {
  return (
    <div className="[--header-height:calc(theme(spacing.14))]">
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <AuthProvider>
          <SidebarProvider defaultOpen={false} className="flex flex-col">
            <Routes>
              <Route path="/viewer/:solutionId" element={<SolutionViewer />} />
              <Route path="*" element={<NotFound />} />

              <Route element={<AuthRoute />}>
                <Route path="" element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="resend/verify-email" element={<ResendVerifyEmail />} />
                <Route path="forgot" element={<ForgotPassword />} />
                <Route path="reset-password/:token" element={<ResetPassword />} />
                <Route path="store/list" element={<StoreList />} />
                <Route path="verify-email/:token" element={<VerifyEmail />} />
              </Route>

              <Route element={<ProtectedRoute />}>
                <Route path="profile" element={<Profile />} />
                <Route path="solution" element={<SolutionList />} />
                <Route path="solution/:solutionId" element={<SolutionEdit />} />
                <Route path="solution/old/:solutionId" element={<SolutionEdit2 />} />
              </Route>
            </Routes>
          </SidebarProvider>
        </AuthProvider>
      </BrowserRouter>
      <Toaster position="top-right" />
    </div>
  );
}

export default App;
