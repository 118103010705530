import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import useAuth from '../../hooks/useAuth';
import { tenantDetail } from '../../services/UserService';

import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { User, Phone, Mail, X, Save, Pencil } from 'lucide-react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { tenantUpdate } from '../../services/UserService';

import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';

const schema = yup
  .object({
    first_name: yup.string().label('First name').required(),
    last_name: yup.string().label('Last name').required(),
    phone: yup.string().label('Phone no.').required(),
    email: yup.string().label('Email').required(),
  })
  .required();

const Profile = () => {
  const title = 'User Profile ';
  const [isEdit, setIsEdit] = useState(false);
  const [tenant, setTenant] = useState({});
  const { getSession } = useAuth();
  const user = getSession();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEditProfile = async (data) => {
    try {
      const response = await tenantUpdate({
        id: tenant.id,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
      });
      if (response.success) {
        toast.success(response.message);
        await getTenant();
        handleIsEdit();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setValue('first_name', tenant.first_name);
    setValue('last_name', tenant.last_name);
    setValue('email', tenant.email);
    setValue('phone', tenant.phone);
  }, [tenant]);

  const handleIsEdit = () => {
    setIsEdit(!isEdit);
  };

  const getTenant = async () => {
    try {
      const response = await tenantDetail(user.id);
      if (response.success) {
        setTenant(response.data);
      } else {
        setTenant({});
      }
    } catch (error) {
      setTenant({});
    }
  };

  useEffect(() => {
    (async () => {
      await getTenant();
    })();
  }, [user.id]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="min-h-screen px-6 py-10">
        <div className="mx-auto max-w-3xl">
          {isEdit ? (
            /** Edit Mode */
            <form onSubmit={handleSubmit(handleEditProfile)} noValidate>
              <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-semibold">
                  Profile ({tenant.first_name} {tenant.last_name})
                </h2>
                <div className="flex space-x-2">
                  <Button variant="outline" onClick={handleIsEdit}>
                    <X size={16} className="mr-1" /> Cancel
                  </Button>
                  <Button type="submit">
                    <Save size={16} className="mr-1" /> Save
                  </Button>
                </div>
              </div>

              <Card className="overflow-hidden shadow-lg">
                <CardHeader className="flex flex-col items-center bg-gray-100 py-6">
                  <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-300">
                    <User size={40} className="text-gray-600" />
                  </div>
                </CardHeader>

                <CardContent className="grid grid-cols-1 gap-6 p-6 md:grid-cols-2">
                  {[
                    { label: 'First Name', id: 'first_name', type: 'text' },
                    { label: 'Last Name', id: 'last_name', type: 'text' },
                    { label: 'Phone No.', id: 'phone', type: 'text' },
                    { label: 'Email', id: 'email', type: 'email', disabled: true },
                  ].map(({ label, id, type, disabled }) => (
                    <div key={id} className="space-y-2">
                      <Label htmlFor={id}>{label}</Label>
                      <Input
                        id={id}
                        type={type}
                        {...register(id)}
                        disabled={disabled}
                        className={errors[id] ? 'border-red-500' : ''}
                      />
                      {errors[id] && <p className="text-red-500 text-sm">{errors[id]?.message}</p>}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </form>
          ) : (
            /** View Mode */
            <>
              <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-semibold">Profile</h2>
                <Button variant="outline" onClick={handleIsEdit}>
                  <Pencil size={16} className="mr-1" /> Edit
                </Button>
              </div>

              <Card className="overflow-hidden shadow-lg">
                <CardHeader className="flex flex-col items-center bg-gray-100 py-6">
                  <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-300">
                    <User size={40} className="text-gray-600" />
                  </div>
                  <CardTitle className="mt-3 text-xl font-semibold">
                    {tenant.first_name} {tenant.last_name}
                  </CardTitle>
                </CardHeader>

                <CardContent className="grid grid-cols-1 gap-6 p-6 md:grid-cols-3">
                  {[
                    { label: 'Name', icon: <User size={20} />, value: `${tenant.first_name} ${tenant.last_name}` },
                    { label: 'Phone No.', icon: <Phone size={20} />, value: tenant.phone || '-' },
                    { label: 'Email', icon: <Mail size={20} />, value: tenant.email },
                  ].map(({ label, icon, value }) => (
                    <div key={label} className="flex items-center gap-3">
                      <div className="text-gray-500">{icon}</div>
                      <div>
                        <p className="text-sm text-gray-500">{label}</p>
                        <p className="font-medium">{value}</p>
                      </div>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Profile;
