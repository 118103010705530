import { ExternalLink, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { DrawerClose } from '../../ui/drawer';
import { Label } from '../../ui/label';
import { Input } from '../../ui/input';
import { Switch } from '../../ui/switch';
import { Button } from '../../ui/button';

import { marketList, solutionUpdate } from '../../../services/SolutionService';
import { getStoreSession } from '../../../services/StoreService';
import { pickerIcon } from '../../../common/icon';

const SolutionSetting = ({ selectedSolution, getSolutions, handleDrawerClose }) => {
  const [solution, setSolution] = useState(selectedSolution);
  const [markets, setMarkets] = useState([]);
  const store = getStoreSession();

  const getMarkets = async () => {
    try {
      const response = await marketList(store.id);
      if (response.success) {
        setMarkets(response.data);
      } else {
        setMarkets([]);
      }
    } catch (error) {
      setMarkets([]);
    }
  };

  const handleUpdateSolution = async () => {
    const solutionJson = {
      id: solution.id,
      store_id: solution.store_id,
      tenant_id: solution.tenant_id,
      theme_style_id: solution.theme_style_id,
      theme_id: solution.theme_id,
      name: solution.name,
      status: solution.status ? 'published' : 'inactive',
      market_id: solution.market_id,
      webbrowser: solution.webbrowser,
      vrheadset: solution.vrheadset,
      showloaderlogo: solution.showloaderlogo,
      customlogo: solution.customlogo,
      showuserhelp: solution.showuserhelp,
      backgroundcolor: solution.backgroundcolor,
      textcolor: solution.textcolor,
      store_url: solution.store_url,
      requires_password: solution.requires_password,
      password: solution.password,
    };
    try {
      const response = await solutionUpdate(solutionJson);
      if (response.success) {
        toast.success(response.message);
        await getSolutions();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (!solution.store_url) {
      const storeSolutionUrl = `${process.env.REACT_APP_URL}/solution/${solution.id}`;
      setSolution({ ...solution, store_url: storeSolutionUrl });
    }
  }, [solution.store_url]);

  useEffect(() => {
    (async () => {
      await getMarkets();
    })();
  }, []);

  return (
    <div className="bg-lightGray6 p-4">
      <div className="flex h-12 items-center justify-between px-10">
        <h1 className="text-xl">Settings</h1>
        <DrawerClose onClick={handleDrawerClose}>
          <X />
        </DrawerClose>
      </div>

      <div className="flex h-12 items-center justify-between px-10">
        <div className="text-xl">{solution?.name}</div>
        <Button
          onClick={handleUpdateSolution}
          variant="outline"
          type="submit"
          className="h-[38px] w-[92px] rounded-md bg-lightBlue3 text-base font-normal capitalize tracking-[0px] hover:bg-lightBlue4"
        >
          Update
        </Button>
      </div>

      <Tabs defaultValue="general" className="mt-4 flex flex-col px-10" style={{ height: 'calc(100vh - 100px)' }}>
        <TabsList className="h-10 w-full shrink-0 justify-start border-b border-lightGray3">
          <TabsTrigger
            className="h-10 px-4 text-base data-[state=active]:border-b-[3px] data-[state=active]:border-b-pink3 data-[state=active]:bg-transparent"
            value="general"
          >
            General
          </TabsTrigger>
          <TabsTrigger
            className="h-10 px-4 text-base data-[state=active]:border-b-[3px] data-[state=active]:border-b-pink3 data-[state=active]:bg-transparent"
            value="multiplayer"
          >
            Multiplayer
          </TabsTrigger>
          <TabsTrigger
            className="h-10 px-4 text-base data-[state=active]:border-b-[3px] data-[state=active]:border-b-pink3 data-[state=active]:bg-transparent"
            value="commerce"
          >
            Commerce
          </TabsTrigger>
        </TabsList>

        <TabsContent value="general" className="overflow-y-auto py-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Solution</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <Label htmlFor="name" className="w-[135px] text-[13px] text-white/80">
                    Name
                  </Label>
                  <Input
                    onChange={(e) => setSolution({ ...solution, name: e.target.value })}
                    value={solution?.name}
                    id="name"
                    type="text"
                    placeholder="Enter Name"
                    className="'border-lightGray5 focus-visible:ring-0' h-[30px] w-[231px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white"
                  />
                </div>
                <div className="flex items-center">
                  <Label htmlFor="market" className="w-[135px] text-[13px] text-white/80">
                    Market
                  </Label>
                  <div className="relative">
                    <select
                      id="market"
                      className="h-[30px] w-[231px] appearance-none rounded-[3px] border border-lightGray5 border-opacity-30 bg-lightGray4 px-2 py-1.5 text-[11px]"
                      value={solution?.market_id}
                      onChange={(e) => setSolution({ ...solution, market_id: e.target.value })}
                    >
                      {markets &&
                        markets.map((market) => (
                          <option key={market.id} value={market.id}>
                            {market.name}
                          </option>
                        ))}
                    </select>{' '}
                    <svg
                      class="pointer-events-none absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
                <div className="flex items-center">
                  <Label htmlFor="status" className="w-[135px] text-[13px] text-white/80">
                    Status
                  </Label>

                  <Switch
                    defaultChecked={solution?.status === 'published'}
                    onCheckedChange={(value) => setSolution({ ...solution, status: value })}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Supported Devices</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <Label htmlFor="web_browser" className="w-[135px] text-[13px] text-white/80">
                    Web Browser
                  </Label>
                  <Switch
                    defaultChecked={solution?.webbrowser}
                    onCheckedChange={(value) => setSolution({ ...solution, webbrowser: value })}
                  />
                </div>
                <div className="flex items-center">
                  <Label htmlFor="vr_headset" className="w-[135px] text-[13px] text-white/80">
                    VR Headset
                  </Label>
                  <Switch
                    defaultChecked={solution?.vrheadset}
                    onCheckedChange={(value) => setSolution({ ...solution, vrheadset: value })}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Loading Page</h1>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <Label htmlFor="show_loader_logo" className="w-[135px] text-[13px] text-white/80">
                      Show Loader Logo
                    </Label>
                    <Switch
                      defaultChecked={solution?.showloaderlogo}
                      onCheckedChange={(value) => setSolution({ ...solution, showloaderlogo: value })}
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <Label htmlFor="backgroundcolor" className="w-[135px] whitespace-nowrap text-end">
                      Background Color
                    </Label>
                    <img src={pickerIcon} />
                    <Input
                      className="h-5 w-9 rounded-none border-2 border-white p-0 shadow-none outline-none"
                      type="color"
                      value={solution?.backgroundcolor}
                      onChange={(e) => setSolution({ ...solution, backgroundcolor: e.target.value })}
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <Label htmlFor="show_user_help" className="w-[135px] text-[13px] text-white/80">
                      Show User Help
                    </Label>
                    <Switch
                      defaultChecked={solution?.showuserhelp}
                      onCheckedChange={(value) => setSolution({ ...solution, showuserhelp: value })}
                    />
                  </div>

                  <div className="flex items-center gap-2">
                    <Label htmlFor="textcolor" className="w-[135px] whitespace-nowrap text-end">
                      Text Color
                    </Label>
                    <img src={pickerIcon} />
                    <Input
                      className="h-5 w-9 rounded-none border-2 border-white p-0 shadow-none outline-none"
                      type="color"
                      value={solution?.textcolor}
                      onChange={(e) => setSolution({ ...solution, textcolor: e.target.value })}
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  <Label htmlFor="customlogo" className="w-[135px] text-[13px] text-white/80">
                    Custom Logo
                  </Label>
                  <Switch
                    defaultChecked={solution?.customlogo}
                    onCheckedChange={(value) => setSolution({ ...solution, customlogo: value })}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Web Access</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1">
                  <Label htmlFor="store_solution_url" className="w-[130px] text-[13px] text-white/80">
                    Store Solution URL
                  </Label>
                  <Input
                    onChange={(e) => setSolution({ ...solution, store_url: e.target.value })}
                    value={solution?.store_url}
                    id="name"
                    type="url"
                    // disabled
                    readOnly
                    className="'border-lightGray5 focus-visible:ring-0' h-[30px] w-[231px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white"
                  />
                  <Link to={solution?.store_url} target="_blank" className="text-lightGray opacity-80">
                    <ExternalLink />
                  </Link>
                </div>
                <div className="flex items-center">
                  <Label htmlFor="requires_password" className="w-[135px] text-[13px] text-white/80">
                    Requires Password
                  </Label>
                  <Switch
                    defaultChecked={solution?.requires_password}
                    onCheckedChange={(value) => setSolution({ ...solution, requires_password: value })}
                  />
                </div>
                <div className="flex items-center">
                  <Label htmlFor="password" className="w-[135px] text-[13px] text-white/80">
                    Password
                  </Label>
                  <Input
                    onChange={(e) => setSolution({ ...solution, password: e.target.value })}
                    value={solution?.password}
                    id="name"
                    type="password"
                    placeholder="Enter Password"
                    className="'border-lightGray5 focus-visible:ring-0' h-[30px] w-[231px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="multiplayer" className="overflow-y-auto py-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">General</h1>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <Label htmlFor="enable_multiplayer" className="w-[135px] text-[13px] text-white/80">
                      Enable Multiplayer
                    </Label>
                    <Switch />
                  </div>
                  <div className="flex items-center">
                    <Label htmlFor="max_viable_user" className="w-[135px] text-[13px] text-white/80">
                      Max Visable Users
                    </Label>
                    <Input
                      id="max_viable_user"
                      type="number"
                      className="'border-lightGray5 focus-visible:ring-0' h-[30px] w-[151px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white"
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  <Label htmlFor="vr_headset" className="w-[135px] text-[13px] text-white/80">
                    Enable Friend Invites
                  </Label>
                  <Switch />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Chat</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <Label htmlFor="enable_voice_chat" className="w-[135px] text-[13px] text-white/80">
                    Enable Voice Chat
                  </Label>
                  <Switch />
                </div>
                <div className="flex items-center">
                  <Label htmlFor="only_friend_chat" className="w-[135px] text-[13px] text-white/80">
                    Only Friends Chat
                  </Label>
                  <Switch />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Store Assistant</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <Label htmlFor="web_browser" className="w-[135px] text-[13px] text-white/80">
                    Enable Store Assist
                  </Label>
                  <Switch />
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="commerce" className="overflow-y-auto py-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Shopping Cart & Checkout</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <Label htmlFor="allow_shopping" className="w-[135px] text-[13px] text-white/80">
                    Allow shopping
                  </Label>
                  <Switch />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="text-base font-medium">Store Assistant</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <Label htmlFor="home_page_url" className="w-[135px] text-[13px] text-white/80">
                    Homepage URL
                  </Label>
                  <Input
                    id="home_page_url"
                    type="text"
                    className="'border-lightGray5 focus-visible:ring-0' h-[30px] w-[231px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white"
                  />
                </div>
                <div className="flex items-center">
                  <Label htmlFor="checkout_url" className="w-[135px] text-[13px] text-white/80">
                    Checkout URL
                  </Label>
                  <Input
                    id="checkout_url"
                    type="text"
                    className="'border-lightGray5 focus-visible:ring-0' h-[30px] w-[231px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SolutionSetting;
