import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { X, Loader2 } from 'lucide-react';

import { toast } from 'sonner';

import useAuth from '../../../hooks/useAuth';
import { getStoreSession } from '../../../services/StoreService';
import { marketList, solutionCreate, themeStyleList } from '../../../services/SolutionService';
import { DrawerClose } from '../../ui/drawer';
import { Label } from '../../ui/label';
import { Input } from '../../ui/input';
import { Button } from '../../ui/button';
import { Dialog } from '../../ui/dialog';

import ThemeSelectorModal from './ThemeSelectorModal';
import { cn } from '../../../utils/common';

const schema = yup
  .object({
    name: yup.string().label('Solution name').required(),
    market_id: yup.string().label('Market').required(),
    status: yup.string().label('Status').required(),
    theme_name: yup.string().label('Theme').required(),
    theme_style_id: yup.string().label('Theme Style').required(),
  })
  .required();

const SolutionCreate = ({ getSolutions, handleDrawerClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [themeStyles, setThemeStyles] = useState([]);
  const { getSession } = useAuth();
  const user = getSession();
  const store = getStoreSession();

  const getMarkets = async () => {
    try {
      const response = await marketList(store.id);
      if (response.success) {
        setMarkets(response.data);
      } else {
        setMarkets([]);
      }
    } catch (error) {
      setMarkets([]);
    }
  };

  const getThemeStyles = async () => {
    try {
      const response = await themeStyleList(selectedTheme.id);
      if (response.success) {
        setThemeStyles(response.data);
      } else {
        setThemeStyles([]);
      }
    } catch (error) {
      setThemeStyles([]);
    }
  };

  const handleShowThemeModal = (value) => {
    setShowThemeModal(value);
  };

  const handleSelectedTheme = (theme) => {
    setSelectedTheme(theme);
    setValue('theme_name', theme.name);
    handleShowThemeModal(false);
  };

  const handleSolutionCreate = async (data) => {
    setIsLoading(true);
    const postData = {
      store_id: store.id,
      tenant_id: user.tenant_id,
      theme_style_id: data.theme_style_id,
      theme_id: selectedTheme.id,
      name: data.name,
      status: data.status,
      market_id: data.market_id,
    };
    try {
      const response = await solutionCreate(postData);
      if (response.success) {
        toast.success(response.message);
        await getSolutions();
        handleDrawerClose();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      await getThemeStyles();
    })();
  }, [selectedTheme]);

  useEffect(() => {
    (async () => {
      await getMarkets();
    })();
  }, []);

  return (
    <div>
      <div className="flex h-12 items-center justify-end p-2 text-white/70">
        <X onClick={handleDrawerClose} size={32} strokeWidth={1.5} />
      </div>
      <Dialog open={showThemeModal} onOpenChange={() => handleShowThemeModal(false)}>
        <div className="px-12 py-4">
          <h1 className="pb-2 text-xl font-medium">New Solution</h1>
          <div>
            <form onSubmit={handleSubmit(handleSolutionCreate)} className="space-y-4" noValidate>
              <div className="space-y-2">
                <Label className="text-[11px] opacity-70 md:text-[13px]" htmlFor="name">
                  Name
                </Label>
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter the solution name"
                  {...register('name')}
                  className={cn(
                    errors.name ? 'border-red/50' : 'border-lightGray5',
                    'h-[30px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white focus-visible:ring-0'
                  )}
                />
                {errors.name && <span className="text-sm text-red/90">{errors.name.message}</span>}
              </div>
              <div className="space-y-2">
                <Label className="text-[11px] opacity-70 md:text-[13px]" htmlFor="market">
                  Market
                </Label>
                <div className="relative">
                  <select
                    id="market"
                    className={`h-[30px] w-full appearance-none rounded-[3px] border border-opacity-30 bg-lightGray4 px-2 py-1.5 text-[11px] ${errors.market_id ? 'border-red/50' : 'border-lightGray5'}`}
                    {...register('market_id')}
                    defaultValue={markets && markets.length > 0 ? markets[0].id : ''}
                  >
                    {markets &&
                      markets.map((market) => (
                        <option key={market.id} value={market.id}>
                          {market.name}
                        </option>
                      ))}
                  </select>{' '}
                  <svg
                    class="pointer-events-none absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>

                {errors.market_id && <span className="text-sm text-red/90">{errors.market_id.message}</span>}
              </div>
              <div className="space-y-2">
                <Label className="text-[11px] opacity-70 md:text-[13px]" htmlFor="store_design_theme">
                  Store Design Theme
                </Label>

                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <Input
                      type="text"
                      {...register('theme_name', { required: 'Theme name is required' })}
                      className={cn(
                        errors.theme_name ? 'border-red/50' : 'border-lightGray5',
                        'h-[30px] rounded-[3px] bg-lightGray4 px-1.5 text-[11px] placeholder:text-white focus-visible:ring-0'
                      )}
                    />
                    {errors.theme_name && <span className="text-sm text-red/90">{errors.theme_name.message}</span>}
                  </div>

                  <Button
                    type="button"
                    className="h-[30px] rounded-sm bg-lightGray3 text-xs capitalize leading-[0px]"
                    onClick={handleShowThemeModal}
                  >
                    Browse
                  </Button>
                </div>
              </div>
              <div className="space-y-2">
                <Label className="text-[11px] opacity-70 md:text-[13px]" htmlFor="theme_style">
                  Theme Style
                </Label>
                <div className="relative">
                  <select
                    className={`h-[30px] w-full appearance-none rounded-[3px] border border-opacity-30 bg-lightGray4 px-2 py-1.5 text-[11px] ${errors.market_id ? 'border-red/50' : 'border-lightGray5'}`}
                    id="theme_style"
                    {...register('theme_style_id')}
                    defaultValue={themeStyles && themeStyles.length > 0 ? themeStyles[0].id : ''}
                  >
                    {themeStyles &&
                      themeStyles.length > 0 &&
                      themeStyles.map((themeStyle) => (
                        <option key={themeStyle.id} value={themeStyle.id}>
                          {themeStyle.name}
                        </option>
                      ))}
                    {themeStyles && themeStyles.length === 0 && <option value="">Select Style</option>}
                  </select>{' '}
                  <svg
                    class="pointer-events-none absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>

                {errors.theme_style_id && <span className="text-sm text-red/90">{errors.theme_style_id.message}</span>}
              </div>
              <div className="space-y-2">
                <Label className="text-[11px] opacity-70 md:text-[13px]" htmlFor="status">
                  Status
                </Label>
                <div className="relative">
                  <select
                    className={`h-[30px] w-full appearance-none rounded-[3px] border border-opacity-30 bg-lightGray4 px-2 py-1.5 text-[11px] ${errors.market_id ? 'border-red/50' : 'border-lightGray5'}`}
                    id="status"
                    {...register('status')}
                  >
                    <option value="published">Published</option>
                    <option value="inactive">InActive</option>
                  </select>{' '}
                  <svg
                    class="pointer-events-none absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>

                {errors.status && <span className="text-sm text-red/90">{errors.status.message}</span>}
              </div>

              <Button
                variant="outline"
                type="submit"
                className="h-[38px] w-[92px] rounded-md bg-lightBlue3 text-base font-normal capitalize tracking-[0px] hover:bg-lightBlue4"
                disabled={isLoading}
              >
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Create
              </Button>
            </form>
          </div>{' '}
          <ThemeSelectorModal handleSelectedTheme={handleSelectedTheme} />
        </div>
      </Dialog>
    </div>
  );
};

export default SolutionCreate;
