import { useCallback, useEffect, useRef, useState } from 'react';

const useDetectOutsideClick = (handleOutsideClick = () => {}) => {
  const [isClicked, setIsClicked] = useState(false);
  const ref = useRef(null);

  const handleClick = useCallback(
    (event) => {
      if (!ref.current?.contains(event.target)) {
        setIsClicked(true);
        handleOutsideClick(event, ref);
      } else {
        setIsClicked(false);
      }
    },
    [handleOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    document.addEventListener('mousedown', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('mousedown', handleClick, true);
    };
  }, [handleClick]);

  return { ref, isClicked, setIsClicked };
};

export default useDetectOutsideClick;
