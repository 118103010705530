import { createContext, useContext, useEffect, useState } from 'react';
import { useFiles } from './FilesContext';
import sortFiles from '../utils/sortFiles';

const FileNavigationContext = createContext();

const FileNavigationProvider = ({ children, defaultPath = '' }) => {
  const [currentPath, setCurrentPath] = useState(defaultPath);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentPathFiles, setCurrentPathFiles] = useState([]);
  const { files } = useFiles();

  useEffect(() => {
    if (Array.isArray(files) && files.length > 0) {
      setCurrentPathFiles(() => {
        const currPathFiles = files.filter((file) => file.path === `${currentPath}/${file.name}`);
        return sortFiles(currPathFiles);
      });
      setCurrentFolder(() => files.find((file) => file.path === currentPath) ?? null);
    }
  }, [files, currentPath]);

  return (
    <FileNavigationContext.Provider
      value={{
        currentPath,
        setCurrentPath,
        currentFolder,
        setCurrentFolder,
        currentPathFiles,
        setCurrentPathFiles,
      }}
    >
      {children}
    </FileNavigationContext.Provider>
  );
};

export default FileNavigationProvider;

export const useFileNavigation = () => useContext(FileNavigationContext);
