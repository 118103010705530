import { FaCheck } from 'react-icons/fa6';
import { useLayout } from '../../../../contexts/LayoutContext';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';

import { LayoutGrid, List } from 'lucide-react';

const LayoutToggler = ({ onLayoutChange }) => {
  const { activeLayout, setActiveLayout } = useLayout();

  const layoutOptions = [
    {
      key: 'grid',
      name: 'Grid',
      icon: <LayoutGrid size={14} />,
    },
    {
      key: 'list',
      name: 'List',
      icon: <List size={14} />,
    },
  ];

  const handleSelection = (key) => {
    setActiveLayout(key);
    onLayoutChange(key);
  };

  return (
    <div className="rounded-md p-1.5 hover:bg-accent">
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>{activeLayout === 'grid' ? <LayoutGrid size={18} /> : <List size={18} />}</TooltipTrigger>
              <TooltipContent side="bottom">
                <p>Change View</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {layoutOptions.map((option) => (
            <DropdownMenuItem
              key={option.key}
              onClick={() => handleSelection(option.key)}
              onKeyDown={() => handleSelection(option.key)}
            >
              <div>{option.key === activeLayout ? <FaCheck size={12} /> : <div className="h-3 w-3" />}</div>
              <span>{option.icon}</span>
              <span>{option.name}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default LayoutToggler;
