import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
} from 'react-bootstrap';
import {
  Stage, Layer,
} from 'react-konva';
import { toast, ToastContainer } from 'react-toastify';

import './solutionedit.css';
import {
  storefront, interior, products, apps, others,
} from '../../../assets/images/virtual';
import ProductList from '../../../components/virtual/solutionedit/ProductList';
import MyAssetLibrary from '../../../components/virtual/solutionedit/MyAssetLibrary';
import ObjectList from '../../../components/virtual/solutionedit/ObjectList';
import PropertyList from '../../../components/virtual/solutionedit/PropertyList';
import { solutionDetail } from '../../../services/SolutionService';
import AppList from '../../../components/virtual/solutionedit/AppList';
import ContentList from '../../../components/virtual/solutionedit/ContentList';
import URLImage from '../../../components/virtual/solutionedit/URLImage';
import FilesProvider from '../../../contexts/FilesContext';
import SelectionProvider from '../../../contexts/SelectionContext';
import FileNavigationProvider from '../../../contexts/FileNavigationContext';
import ClipBoardProvider from '../../../contexts/ClipboardContext';
import LayoutProvider from '../../../contexts/LayoutContext';
import useTriggerAction from '../../../hooks/useTriggerAction';
import ThemesList from '../../../components/virtual/solutionedit/ThemesList';
import Actions from '../../../components/virtual/Explorer/Actions/Actions';
import useFileManager from '../../../components/virtual/Explorer/useFileManager';

const SolutionEdit = () => {
  // eslint-disable-next-line no-unused-vars
  const [solution, setSolution] = useState({});
  const { solutionId } = useParams();
  const [showContent, setShowContent] = useState(true);
  const [contentSelected, setContentSelected] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [showThemes, setShowThemes] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const stageRef = useRef();
  const parentDivRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [draggedItem, setDraggedItem] = useState(null);
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [selectedCanvasObject, setSelectedCanvasObject] = useState(null);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);

  // File Management
  const triggerAction = useTriggerAction();
  const {
    files,
    handleCreateFolder,
    handleFileUploading,
    handleFileUploaded,
    handleRename,
    handleDelete,
    handlePaste,
    handleLayoutChange,
    handleRefresh,
    handleFileOpen,
    handleError,
    handleDownload,
  } = useFileManager();

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedCanvasObject(null);
    }
  };

  const handleShow = (option, data) => {
    setContentSelected(data);
    setOptionSelected(option);
    if (option === 1) {
      setShowContent(true);
      setShowProduct(false);
      setShowApp(false);
      setShowThemes(false);
    } else if (option === 2) {
      setShowContent(false);
      setShowProduct(true);
      setShowApp(false);
      setShowThemes(false);
      // setContentSelected({});
    } else if (option === 3) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(true);
      setShowThemes(false);
      // setContentSelected({});
    } else if (option === 4) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(false);
      setShowThemes(true);
    }
  };

  const getSolution = async () => {
    try {
      const response = await solutionDetail(solutionId);
      if (response.success) {
        setSolution(response.data);
      } else {
        setSolution({});
      }
    } catch (error) {
      setSolution({});
    }
  };

  const updateObjectItems = (folders, searchText, newItems) => folders.map((folder) => {
    if (folder.path === searchText) {
      return {
        ...folder,
        items: [...folder.items, newItems],
      };
    }
    if (folder.items && folder.items.length > 0) {
      return {
        ...folder,
        items: updateObjectItems(folder.items, searchText, newItems),
      };
    }
    return folder;
  });

  const addItemsToObjects = (newItems, searchText) => {
    setObjects((prevData) => updateObjectItems(prevData, searchText, newItems));
  };

  const addItemsToCanvas = (newItems) => {
    canvasObjects.push(newItems);
    setCanvasObjects(canvasObjects);
  };

  useEffect(() => {
    (async () => {
      await getSolution();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionId]);

  useEffect(() => {
    if (parentDivRef.current?.offsetHeight && parentDivRef.current?.offsetWidth) {
      setDimensions({
        width: parentDivRef.current.offsetWidth,
        height: parentDivRef.current.offsetHeight,
      });
    }
  }, [parentDivRef.current?.offsetWidth, parentDivRef.current?.offsetHeight]);

  useEffect(() => {
    if (solution.ThemeStyle) {
      const themeRooms = solution.ThemeStyle?.ThemeRoom;
      if (themeRooms && themeRooms.length > 0) {
        const availableRooms = [];
        themeRooms.forEach((themeRoom) => {
          const roomObj = {
            id: themeRoom.id,
            name: themeRoom.name,
            isFolder: true,
            src: storefront,
            path: `/${themeRoom.name}`,
            isDefault: themeRoom.isDefault,
            items: [{
              id: `${themeRoom.id}_1`,
              name: 'Interior',
              isFolder: true,
              src: interior,
              path: `/${themeRoom.name}/Interior`,
              items: [],
            },
            {
              id: `${themeRoom.id}_2`,
              name: 'Products',
              isFolder: true,
              src: products,
              path: `/${themeRoom.name}/Products`,
              items: [],
            },
            {
              id: `${themeRoom.id}_3`,
              name: 'Apps',
              isFolder: true,
              src: apps,
              path: `/${themeRoom.name}/Apps`,
              items: [],
            },
            {
              id: `${themeRoom.id}_4`,
              name: 'Others',
              isFolder: true,
              src: others,
              path: `/${themeRoom.name}/Others`,
              items: [],
            },
            ],
          };
          availableRooms.push(roomObj);
        });
        setObjects(availableRooms);
        themeRooms.forEach((themeRoom) => {
          const customizations = themeRoom.Customization;
          if (customizations && customizations.length > 0) {
            customizations.forEach((customization) => {
              const object = customization.Object;
              if (object) {
                const objObj = {
                  id: object.id,
                  name: object.name,
                  isFolder: false,
                  src: object.file_share_url,
                  path: `/${themeRoom.name}/Interior/${object.name}`,
                };
                addItemsToObjects(objObj, `/${themeRoom.name}/Interior`);
                const canvasObj = {
                  src: object.file_share_url,
                  x: customization.position_x,
                  y: customization.position_y,
                  height: customization.measure_h,
                  width: customization.measure_w,
                  id: object.id,
                  name: object.name,
                };
                addItemsToCanvas(canvasObj);
              }
            });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solution]);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="virtualAssets">
        <Row className="m-0">
          <div className="p-0 virtualCanvasArea">
            <div className="asstesCount">
              <p className="mb-0 text-end text-white fs-12">FPS: 127ms</p>
            </div>
            <div className="canvasFrame"
              onDrop={(e) => {
                e.preventDefault();
                // register event position
                if (selectedObject) {
                  stageRef.current.setPointersPositions(e);
                  // add image
                  setCanvasObjects(
                    canvasObjects.concat([
                      {
                        ...stageRef.current.getPointerPosition(),
                        src: draggedItem.src,
                        height: draggedItem.height,
                        width: draggedItem.width,
                        id: draggedItem.id,
                        name: draggedItem.name,
                      },
                    ]),
                  );
                  setSelectedCanvasObject({
                    src: draggedItem.src,
                    height: draggedItem.height,
                    width: draggedItem.width,
                    id: draggedItem.id,
                    name: draggedItem.name,
                  });
                  const firstPart = selectedObject ? selectedObject?.path?.split('/')[1] : 'Store Front';
                  if (draggedItem?.isProduct) {
                    const newItem = {
                      id: draggedItem.id,
                      name: draggedItem.name,
                      isFolder: false,
                      src: draggedItem.src,
                      path: `/${firstPart}/Products/${draggedItem.name}`,
                    };
                    addItemsToObjects(newItem, `/${firstPart}/Products`);
                  } else {
                    const newItem = {
                      id: draggedItem.id,
                      name: draggedItem.name,
                      isFolder: false,
                      src: draggedItem.src,
                      path: `/${firstPart}/Interior/${draggedItem.name}`,
                    };
                    addItemsToObjects(newItem, `/${firstPart}/Interior`);
                  }
                } else {
                  toast.error('Please select atleast one room.');
                }
              }}
              onDragOver={(e) => e.preventDefault()}
              ref={parentDivRef}
            >
              <Stage
                width={dimensions.width}
                height={dimensions.height}
                ref={stageRef}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
              >
                <Layer>
                  {canvasObjects.map((canvasObject, i) => (
                    <URLImage
                      key={i}
                      imgProps={canvasObject}
                      isSelected={canvasObject.id === selectedCanvasObject?.id}
                      onSelect={() => {
                        setSelectedCanvasObject(canvasObject);
                      }}
                      onChange={(newAttrs) => {
                        const objs = canvasObjects.slice();
                        objs[i] = newAttrs;
                        setCanvasObjects(objs);
                        setSelectedCanvasObject(newAttrs);
                      }}
                    />
                  ))}
                </Layer>
              </Stage>
            </div>
            <div className="myAssets">
              <div className="searchProduct">
                <Row className='h-100'>
                  <FilesProvider filesData={files} onError={handleError}>
                    <FileNavigationProvider defaultPath='/Content'>
                      <SelectionProvider onDownload={handleDownload}>
                        <ClipBoardProvider onPaste={handlePaste}>
                          <LayoutProvider layout="grid">
                            <div className="pe-0 leftAssets h-100">
                              <MyAssetLibrary
                                optionSelected={optionSelected}
                                handleShow={handleShow}
                                contentSelected={contentSelected}
                              />
                            </div>
                            <div className="ps-0 rightAssets h-100">
                              {showContent
                                && (<ContentList
                                  setDraggedItem={setDraggedItem}
                                  contentSelected={contentSelected}
                                  triggerAction={triggerAction}
                                  onFileOpen={handleFileOpen}
                                  onLayoutChange={handleLayoutChange}
                                  onRefresh={handleRefresh}
                                  onCreateFolder={handleCreateFolder}
                                  onRename={handleRename}
                                  enableFilePreview
                                />)}
                              {showProduct && <ProductList setDraggedItem={setDraggedItem} />}
                              {showApp && <AppList />}
                              {showThemes && <ThemesList />}
                            </div>
                            <Actions
                              // fileUploadConfig={fileUploadConfig}
                              onFileUploading={handleFileUploading}
                              onFileUploaded={handleFileUploaded}
                              onDelete={handleDelete}
                              onRefresh={handleRefresh}
                              maxFileSize={20485760}
                              // filePreviewPath={filePreviewPath}
                              acceptedFileTypes=".png, .jpeg, .svg, .glb, .mp3, .mp4"
                              triggerAction={triggerAction}
                            />
                          </LayoutProvider>
                        </ClipBoardProvider>
                      </SelectionProvider>
                    </FileNavigationProvider>
                  </FilesProvider>
                </Row>
              </div>
            </div>
          </div>
          <div className="p-0 sidebarCanvas">
            <div className="topSideBar">
              <PropertyList selectedCanvasObject={selectedCanvasObject} />
            </div>
            <div className="bottomSideBar">
              <ObjectList objects={objects} selectedObject={selectedObject} setSelectedObject={setSelectedObject} />
            </div>
          </div>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default SolutionEdit;
