// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userProfile i {
    font-size: 150px;
    line-height: 150px;
    color: #d1d2d3;
}
.userProfile .card-title {
    margin: 0;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;
}
.userProfile {
    border-bottom: 0.0625rem solid #e1e3e5;
}
.icon-with-text i {
    background: #e4e5e7;
    color: #5c5f62;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 18px;
}


@media (max-width: 767px) {
.my_2 {
    margin: 1.2rem 0;
}
.userProfile i {
    font-size: 100px;
    line-height: 100px;
}



}`, "",{"version":3,"sources":["webpack://./src/pages/Merchant/profile.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,SAAS;IACT,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,sCAAsC;AAC1C;AACA;IACI,mBAAmB;IACnB,cAAc;IACd,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;;AAGA;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;;;AAIA","sourcesContent":[".userProfile i {\n    font-size: 150px;\n    line-height: 150px;\n    color: #d1d2d3;\n}\n.userProfile .card-title {\n    margin: 0;\n    text-transform: uppercase;\n    margin-top: 10px;\n    font-weight: 400;\n    font-size: 18px;\n}\n.userProfile {\n    border-bottom: 0.0625rem solid #e1e3e5;\n}\n.icon-with-text i {\n    background: #e4e5e7;\n    color: #5c5f62;\n    border-radius: 100px;\n    width: 36px;\n    height: 36px;\n    text-align: center;\n    line-height: 36px;\n    font-size: 18px;\n}\n\n\n@media (max-width: 767px) {\n.my_2 {\n    margin: 1.2rem 0;\n}\n.userProfile i {\n    font-size: 100px;\n    line-height: 100px;\n}\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
