import { useCallback, useEffect, useMemo, useState } from 'react';

import Collapsible from 'react-collapsible';
import getParentPath from '../../../utils/getParentPath';
import FolderTree from '../Explorer/FolderTree/FolderTree';
import { useFiles } from '../../../contexts/FilesContext';
import { useFileNavigation } from '../../../contexts/FileNavigationContext';
import { AppWindow, Box, ChevronRight, Folder, PaintbrushVertical } from 'lucide-react';
import { cn } from '../../../utils/common';
import { appIcon, brushIcon, FolderIcon, productIcon } from '../../../common/icon';

const MyAssetLibrary = ({ optionSelected, handleShow, contentSelected }) => {
  const [foldersTree, setFoldersTree] = useState([]);
  const { files } = useFiles();
  const { currentPath, setCurrentPath } = useFileNavigation();

  const handleShowClick = (option, data = {}) => {
    handleShow(option, data);
    option !== 1 && setCurrentPath(''); // Reset Path
  };

  const createChildRecursive = useCallback((path, foldersStruct) => {
    if (!foldersStruct[path]) return []; // No children for this path (folder)

    return foldersStruct[path]?.map((folder) => ({
      ...folder,
      subDirectories: createChildRecursive(folder.path, foldersStruct),
    }));
  }, []);

  const isContentActive = useMemo(() => optionSelected === 1 && currentPath === '', [optionSelected, currentPath]);
  const [isContentOpen, setIsContentOpen] = useState(false);

  useEffect(() => {
    if (currentPath !== '') {
      setIsContentOpen(true);
    }
  }, [currentPath]);

  useEffect(() => {
    if (Array.isArray(files)) {
      const folders = files.filter((file) => file.isDirectory);
      // Grouping folders by parent path
      const foldersStruct = Object.groupBy(folders, ({ path }) => getParentPath(path));
      setFoldersTree(() => {
        const rootPath = '';
        return createChildRecursive(rootPath, foldersStruct);
      });
    }
  }, [files, createChildRecursive]);

  return (
    <div className="flex h-full flex-col gap-0.5 bg-darkGray">
      <h6 key="my_assets" className="text-nowrap bg-lightGray3 px-4 py-3 text-xs">
        My Assets Library
      </h6>
      <div className="h-full overflow-y-auto bg-muted pb-20">
        <div
          className={`flex cursor-pointer items-center gap-1 p-2 hover:bg-accent ${isContentActive ? 'bg-accent' : ''} ${foldersTree?.length === 0 ? 'pl-6' : ''}`}
          onClick={() => {
            handleShowClick(1);
            setCurrentPath('');
          }}
        >
          {foldersTree?.length > 0 ? (
            <span
              onClick={(e) => {
                e.stopPropagation();
                setIsContentOpen((prev) => !prev);
              }}
              className={cn('transition-transform duration-300', isContentOpen ? 'rotate-90' : 'rotate-0')}
            >
              <ChevronRight size={18} />
            </span>
          ) : (
            <span className="w-1" />
          )}
          <div className="flex items-center gap-2 text-[11px]">
            <FolderIcon className="h-4 w-4" alt="folder" size={18} />
            <span title={'Content'}>Content</span>
          </div>
        </div>
        <Collapsible open={isContentOpen}>
          <div className="ml-6">
            {foldersTree?.map((folder, index) => (
              <FolderTree key={index} folder={folder} handleShowClick={handleShowClick} />
            ))}
          </div>
        </Collapsible>

        {[
          { key: 2, label: 'Products', icon: productIcon },
          { key: 3, label: 'Apps', icon: appIcon },
          { key: 4, label: 'Themes', icon: brushIcon, style: 'w-3.5 ml-0.5' },
        ].map(({ key, label, icon: Icon, style }) => (
          <p
            key={label}
            onClick={() => handleShowClick(key)}
            className={cn(
              'font-md flex cursor-pointer items-center gap-2 py-2 pl-8 text-[11px]',
              optionSelected === key ? 'bg-lightGray8' : ''
            )}
          >
            <img src={Icon} className={cn(style, 'h-fit')} />
            <span>{label}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default MyAssetLibrary;
