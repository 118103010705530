// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-delete-confirm {
    .file-delete-confirm-text {
        border-bottom: 1px solid #dddddd;
        padding: 15px;
        margin-top: 0;
        margin-bottom: .7rem;
        word-wrap: break-word;
        font-weight: 500;
        color: white;
    }

    .file-delete-confirm-actions {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
        margin-bottom: .7rem;
        margin-right: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/virtual/Explorer/Actions/Delete/Delete.action.css"],"names":[],"mappings":"AAAA;IACI;QACI,gCAAgC;QAChC,aAAa;QACb,aAAa;QACb,oBAAoB;QACpB,qBAAqB;QACrB,gBAAgB;QAChB,YAAY;IAChB;;IAEA;QACI,aAAa;QACb,WAAW;QACX,yBAAyB;QACzB,oBAAoB;QACpB,kBAAkB;IACtB;AACJ","sourcesContent":[".file-delete-confirm {\n    .file-delete-confirm-text {\n        border-bottom: 1px solid #dddddd;\n        padding: 15px;\n        margin-top: 0;\n        margin-bottom: .7rem;\n        word-wrap: break-word;\n        font-weight: 500;\n        color: white;\n    }\n\n    .file-delete-confirm-actions {\n        display: flex;\n        gap: 0.5rem;\n        justify-content: flex-end;\n        margin-bottom: .7rem;\n        margin-right: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
