// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-rename-folder-container {
  padding: 8px 0;

  .fm-rename-folder-input {
    border-bottom: 1px solid #c6c6c6;
    padding: 8px 12px 12px;

    .fm-rename-warning {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 1.2em;
    }
  }

  .fm-rename-folder-action {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 8px 8px 0 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/virtual/Explorer/Actions/Rename/Rename.action.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd;IACE,gCAAgC;IAChC,sBAAsB;;IAEtB;MACE,aAAa;MACb,mBAAmB;MACnB,SAAS;MACT,gBAAgB;IAClB;EACF;;EAEA;IACE,aAAa;IACb,QAAQ;IACR,yBAAyB;IACzB,oBAAoB;EACtB;AACF","sourcesContent":[".fm-rename-folder-container {\n  padding: 8px 0;\n\n  .fm-rename-folder-input {\n    border-bottom: 1px solid #c6c6c6;\n    padding: 8px 12px 12px;\n\n    .fm-rename-warning {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n      font-size: 1.2em;\n    }\n  }\n\n  .fm-rename-folder-action {\n    display: flex;\n    gap: 8px;\n    justify-content: flex-end;\n    padding: 8px 8px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
