import { useEffect, useState } from 'react';

import { Label } from '../../ui/label';
import { Input } from '../../ui/input';
import { Switch } from '../../ui/switch';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/accordion';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { cn } from '../../../utils/common';

const PRECISION = 2;
const DRAG_SENSITIVITY = 0.01;

const PropertyList = ({ selectedModel, handleTransformChange, handlePhysicsChange }) => {
  const { id, modelData: { position, rotation, scale, type, physics } = {} } = selectedModel || {};

  const handleValueChange = (title, property, value) => {
    let temp = { ...selectedModel.modelData };
    temp[title][property] = +value;
    handleTransformChange(id, type, temp.position, temp.rotation, temp.scale);
  };

  const handlePhysicsValueChange = (title, path, value) => {
    const newPhyConfig = { ...physics };

    let current = newPhyConfig;

    const keys = path.split('.');
    keys.slice(0, -1).forEach((key) => {
      if (!current[key]) current[key] = {};
      current = current[key];
    });

    current[keys[keys.length - 1]] = value;

    handlePhysicsChange(id, type, newPhyConfig);
  };

  return (
    <div className="h-full bg-darkGray py-px">
      <Tabs defaultValue="properties" className="h-full">
        <TabsList>
          <TabsTrigger
            className="pb-[3px] pl-[15px] pr-[25px] pt-[7px] text-[11px]"
            value="properties"
            aria-controls="example-collapse-text"
          >
            Properties
          </TabsTrigger>
        </TabsList>
        <div className="h-full overflow-y-auto bg-lightGray3 pb-12">
          {!selectedModel ? (
            <div className="flex h-full items-center justify-center text-[11px]">Please select the item</div>
          ) : (
            <TabsContent value="properties">
              <Accordion type="single" collapsible>
                <AccordionItem value="Measure" className="px-2">
                  <AccordionTrigger className="text-[11px]">Measure</AccordionTrigger>
                  <AccordionContent className="flex gap-2 px-4">
                    <div className="flex items-center">
                      <Label className="w-4 text-[10px] text-white/60" htmlFor="measure_w">
                        W
                      </Label>
                      <DraggableInput
                        title={'scale'}
                        axis={'x'}
                        value={scale.x || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="measure_d">
                        D
                      </Label>
                      <DraggableInput
                        title={'scale'}
                        axis={'y'}
                        value={scale.y || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="measure_h">
                        H
                      </Label>
                      <DraggableInput
                        title={'scale'}
                        axis={'z'}
                        value={scale.z || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <Accordion type="single" collapsible>
                <AccordionItem value="Position" className="px-2">
                  <AccordionTrigger className="text-[11px]">Position</AccordionTrigger>
                  <AccordionContent className="flex gap-2 px-4 pl-5">
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="position_x ">
                        X
                      </Label>

                      <DraggableInput
                        title={'position'}
                        axis={'x'}
                        value={position.x || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="position_y">
                        Y
                      </Label>
                      <DraggableInput
                        title={'position'}
                        axis={'y'}
                        value={position.y || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="position_z">
                        Z
                      </Label>
                      <DraggableInput
                        title={'position'}
                        axis={'z'}
                        value={position.z || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <Accordion type="single" collapsible>
                <AccordionItem value="Rotation" className="px-2">
                  <AccordionTrigger className="text-[11px]">Rotation</AccordionTrigger>
                  <AccordionContent className="flex gap-2 px-4 pl-5">
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="rotation_x">
                        X
                      </Label>
                      <DraggableInput
                        title={'rotation'}
                        axis={'x'}
                        value={rotation.x || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="rotation_y">
                        Y
                      </Label>
                      <DraggableInput
                        title={'rotation'}
                        axis={'y'}
                        value={rotation.y || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <Label className="w-3 text-[10px] text-white/60" htmlFor="rotation_z">
                        Z
                      </Label>
                      <DraggableInput
                        title={'rotation'}
                        axis={'z'}
                        value={rotation.z || 0}
                        handleValueChange={handleValueChange}
                      />
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>

              <Accordion type="single" collapsible>
                <AccordionItem value="Physics" className="px-2">
                  <AccordionTrigger className="text-[11px]">Physics</AccordionTrigger>
                  <AccordionContent className="flex flex-col gap-2 px-4 pl-5">
                    <div className="flex items-center">
                      <Label className="w-16 text-[10px] text-white/60" htmlFor="enable">
                        Enable
                      </Label>

                      <Switch
                        checked={physics.enable}
                        onCheckedChange={(value) => handlePhysicsValueChange('physics', 'enable', value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="w-16 text-[10px] capitalize text-white/60" htmlFor="rigidBody">
                        rigidBody
                      </Label>
                      <div className="flex flex-col gap-2 px-4">
                        <div className="flex items-center">
                          <Label className="w-20 text-[10px] capitalize text-white/60" htmlFor="gravityScale">
                            gravityScale
                          </Label>
                          <DraggableInput
                            title={'physics'}
                            axis={'rigidBody.gravityScale'}
                            value={physics.rigidBody.gravityScale}
                            handleValueChange={handlePhysicsValueChange}
                          />
                        </div>
                        <div className="flex items-center">
                          <Label className="w-20 text-[10px] capitalize text-white/60" htmlFor="mass">
                            mass
                          </Label>
                          <DraggableInput
                            title={'physics'}
                            axis={'rigidBody.mass'}
                            value={physics.rigidBody.mass}
                            handleValueChange={handlePhysicsValueChange}
                          />
                        </div>
                        <div className="flex items-center">
                          <Label htmlFor="type" className="w-20 text-[10px] capitalize text-white/60">
                            type
                          </Label>
                          <div className="relative">
                            <select
                              id="type"
                              className="h-[22px] w-24 appearance-none rounded-[3px] border border-lightGray5 border-opacity-30 bg-lightGray4 px-1.5 text-[11px] focus:outline-none"
                              value={physics.rigidBody.type}
                              onChange={(e) => handlePhysicsValueChange('physics', 'rigidBody.type', e.target.value)}
                            >
                              {['fixed', 'dynamic'].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>{' '}
                            <svg
                              class="pointer-events-none absolute right-1 top-1/2 h-5 w-5 -translate-y-1/2 transform"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="w-16 text-[10px] capitalize text-white/60" htmlFor="collider">
                        collider
                      </Label>
                      <div className="flex flex-col gap-2 px-4">
                        <div className="flex items-center">
                          <Label className="w-20 text-[10px] capitalize text-white/60" htmlFor="density">
                            density
                          </Label>
                          <DraggableInput
                            title={'physics'}
                            axis={'collider.density'}
                            value={physics.collider.density}
                            handleValueChange={handlePhysicsValueChange}
                          />
                        </div>
                        <div className="flex items-center">
                          <Label className="w-20 text-[10px] capitalize text-white/60" htmlFor="friction">
                            friction
                          </Label>
                          <DraggableInput
                            title={'physics'}
                            axis={'collider.friction'}
                            value={physics.collider.friction}
                            handleValueChange={handlePhysicsValueChange}
                          />
                        </div>
                        <div className="flex items-center">
                          <Label className="w-20 text-[10px] capitalize text-white/60" htmlFor="restitution">
                            restitution
                          </Label>
                          <DraggableInput
                            title={'physics'}
                            axis={'collider.restitution'}
                            value={physics.collider.restitution}
                            handleValueChange={handlePhysicsValueChange}
                          />
                        </div>
                        <div className="flex items-center">
                          <Label htmlFor="shape" className="w-20 text-[10px] capitalize text-white/60">
                            shape
                          </Label>
                          <div className="relative">
                            <select
                              id="shape"
                              className="h-[22px] w-24 appearance-none rounded-[3px] border border-lightGray5 border-opacity-30 bg-lightGray4 px-1.5 text-[11px] focus:outline-none"
                              value={physics.collider.shape}
                              onChange={(e) => handlePhysicsValueChange('physics', 'collider.shape', e.target.value)}
                            >
                              {['trimesh', 'box', 'sphere', 'capsule', 'cylinder'].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>{' '}
                            <svg
                              class="pointer-events-none absolute right-1 top-1/2 h-5 w-5 -translate-y-1/2 transform"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </TabsContent>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default PropertyList;

const DraggableInput = ({ title, axis, value, handleValueChange }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);

  // Handle drag start
  const handleDragStart = (e) => {
    setIsDragging(true);
    setDragStartX(e.clientX);
    document.body.style.cursor = 'ew-resize';

    // Prevent text selection during drag
    e.preventDefault();
  };

  // Handle mouse move for dragging
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      // Calculate the drag distance and update value
      const deltaX = e.clientX - dragStartX;
      const newValue = Number.parseFloat(value) + deltaX * DRAG_SENSITIVITY;
      handleValueChange(title, axis, newValue.toFixed(PRECISION));
      setDragStartX(e.clientX);
    };

    // Handle mouse up to stop dragging
    const handleMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        document.body.style.cursor = 'default';
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStartX, value, handleValueChange, title, axis]);

  return (
    <div className="flex bg-lightGray4">
      <Input
        type="number"
        name={axis}
        value={Number.parseFloat(value).toFixed(PRECISION)}
        onChange={(e) => handleValueChange(title, e.target.name, e.target.value)}
        className="h-[22px] w-[50px] rounded-none rounded-bl-[3px] rounded-tl-[3px] border-lightGray5 bg-lightGray4 px-1.5 text-[11px] placeholder:text-white focus-visible:ring-0"
      />{' '}
      <i
        className={cn(
          'bi bi-pencil-fill flex h-[22px] w-[17px] cursor-pointer items-center justify-center rounded-none rounded-br-[3px] rounded-tr-[3px] border border-l-0 border-lightGray5 bg-lightGray9 text-[10px] text-lightGray10 transition-all duration-300 hover:bg-lightGray8',
          isDragging && 'cursor-ew-resize bg-lightGray8'
        )}
        onMouseDown={handleDragStart}
      ></i>
    </div>
  );
};
