import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getAllFiles,
  createFolder,
  deleteFile,
  downloadFile,
  copyFiles,
  moveFiles,
  renameFile,
} from '../services/AssetsService';
import { getStoreSession } from '../services/StoreService';

const useFileManager = () => {
  const fileUploadConfig = useMemo(
    () => ({
      url: `${process.env.REACT_APP_API_FUNC_URL}/FileManagerUploadAssetsFile`,
    }),
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const isMountRef = useRef(false);
  const store = getStoreSession();

  // Get Files
  const getFiles = async () => {
    try {
      setIsLoading(true);
      const response = await getAllFiles(store.id);
      setFiles(response.data);
    } catch (error) {
      // console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isMountRef.current) {
      getFiles();
      isMountRef.current = true;
    }
  }, []);
  //

  // Create Folder
  const handleCreateFolder = async (name, parentFolder) => {
    try {
      setIsLoading(true);
      const response = await createFolder(store.id, name, parentFolder?.id);
      setFiles((prev) => [...prev, response.data]);
    } catch (error) {
      // console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  // File Upload Handlers
  const handleFileUploading = (file, parentFolder) => ({
    parentId: parentFolder?.id,
    storeId: store.id,
  });
  const handleFileUploaded = (response) => {
    const { data } = JSON.parse(response);
    setFiles((prev) => [...prev, data]);
  };
  //

  // Rename File/Folder
  const handleRename = async (file, newName) => {
    try {
      setIsLoading(true);
      await renameFile(store.id, file.id, newName);
      await getFiles();
      return true;
    } catch (error) {
      // console.error(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  //

  // Delete File/Folder
  const handleDelete = async (filesData) => {
    try {
      setIsLoading(true);
      const idsToDelete = filesData.map((file) => file.id);
      await deleteFile(store.id, idsToDelete);
      getFiles();
    } catch (error) {
      // console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //

  // Paste File/Folder
  const handlePaste = async (copiedItems, destinationFolder, operationType) => {
    try {
      setIsLoading(true);
      const copiedItemIds = copiedItems.map((item) => item.id);
      if (operationType === 'copy') {
        await copyFiles(store.id, copiedItemIds, destinationFolder?.id);
      } else {
        await moveFiles(store.id, copiedItemIds, destinationFolder?.id);
      }
      await getFiles();
    } catch (error) {
      // console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //

  const handleLayoutChange = (layout) => {
    // console.log(layout);
  };

  // Refresh Files
  const handleRefresh = () => {
    getFiles();
  };
  //

  const handleFileOpen = (file) => {
    // console.log(`Opening file: ${file.name}`);
  };

  const handleError = (error, file) => {
    // console.error(error);
  };

  const handleDownload = async (filesData) => {
    await downloadFile(store.id, filesData);
  };

  return {
    fileUploadConfig,
    files,
    setFiles,
    isLoading,
    getFiles,
    handleCreateFolder,
    handleFileUploading,
    handleFileUploaded,
    handleRename,
    handleDelete,
    handlePaste,
    handleLayoutChange,
    handleRefresh,
    handleFileOpen,
    handleError,
    handleDownload,
  };
};

export default useFileManager;
