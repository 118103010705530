import React, { useState } from 'react';
import { DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../ui/dialog';
import { Separator } from '../../ui/separator';
import { Button } from '../../ui/button';
import { toast } from 'sonner';

import { solutionUpdate } from '../../../services/SolutionService';
import { solutionDelete } from '../../../services/SolutionService';
import { Loader2, X } from 'lucide-react';

const Modal = ({ solutionVisibility, selectedSolution, getSolutions, handleShowModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSolutionPublish = async () => {
    try {
      const solutionJson = {
        id: selectedSolution.id,
        store_id: selectedSolution.store_id,
        tenant_id: selectedSolution.tenant_id,
        theme_style_id: selectedSolution.theme_style_id,
        theme_id: selectedSolution.theme_id,
        name: selectedSolution.name,
        status: 'published',
        market_id: selectedSolution.market_id,
        webbrowser: selectedSolution.webbrowser,
        vrheadset: selectedSolution.vrheadset,
        showloaderlogo: selectedSolution.showloaderlogo,
        customlogo: selectedSolution.customlogo,
        showuserhelp: selectedSolution.showuserhelp,
        backgroundcolor: selectedSolution.backgroundcolor,
        textcolor: selectedSolution.textcolor,
        store_url: selectedSolution.store_url,
        requires_password: selectedSolution.requires_password,
        password: selectedSolution.password,
      };
      setIsLoading(true);
      const { success, message } = await solutionUpdate(solutionJson);
      if (success) {
        toast.success('Solution has been published.');
        await getSolutions();
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      handleShowModal(false);
    }
  };

  const handleSolutionDelete = async () => {
    setIsLoading(true);
    try {
      const { success, message } = await solutionDelete({ id: selectedSolution.id });

      if (success) {
        await getSolutions();
        toast.success('Solution has been removed.');
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      handleShowModal(false);
    }
  };

  const handleConfirmButton = () => {
    solutionVisibility.publish && handleSolutionPublish();
    solutionVisibility.delete && handleSolutionDelete();
  };

  return (
    <DialogContent className="max-w-ml h-fit bg-white">
      <DialogHeader className="!mb-0 flex items-center pt-1">
        <div className="flex h-full w-full items-center justify-between p-4 pb-5 text-lightBlack3">
          <DialogTitle className="text-xl font-medium">
            {solutionVisibility.publish && <div>Publish Solution</div>}
            {solutionVisibility.delete && <div>Delete Solution</div>}
          </DialogTitle>
          <DialogClose>
            <X size={28} strokeWidth={2} className="text-black/70" />
          </DialogClose>
        </div>
      </DialogHeader>
      <Separator className="bg-lightGray2" />

      <div className="flex gap-2 p-4 pb-8 text-lightBlack3">
        {solutionVisibility.publish && (
          <div>
            <p>Are you sure you want to Publish?</p>
          </div>
        )}

        {solutionVisibility.delete && (
          <div>
            <p>Are you sure that you want to delete this solution and all of it&apos;s customizations?</p>
          </div>
        )}
      </div>
      <Separator className="bg-lightGray2" />
      <DialogFooter className="p-4">
        <DialogClose asChild>
          <Button
            type="button"
            variant="secondary"
            className="h-[38px] w-[47px] rounded-md bg-lightBlue3 text-base font-normal capitalize tracking-[0px] hover:bg-lightBlue4"
          >
            No
          </Button>
        </DialogClose>

        <Button
          type="button"
          className="h-[38px] w-[62px] rounded-md bg-lightBlue3 text-base font-normal capitalize tracking-[0px] hover:bg-lightBlue4"
          variant="secondary"
          disabled={isLoading}
          onClick={handleConfirmButton}
        >
          {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Yes
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default Modal;
