import { useEffect, useState } from 'react';
import { Image as DefaultThumbnail, X } from 'lucide-react';

import { Button } from '../../ui/button';
import { DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../ui/dialog';
import { themeList } from '../../../services/SolutionService';
import { Separator } from '../../ui/separator';
import { noImageIcon } from '../../../common/icon';

const ThemeSelectorModal = ({ handleSelectedTheme }) => {
  const [themes, setThemes] = useState([]);

  const getThemes = async () => {
    try {
      const response = await themeList();
      if (response.success) {
        setThemes(response.data);
      } else {
        setThemes([]);
      }
    } catch (error) {
      setThemes([]);
    }
  };

  useEffect(() => {
    (async () => {
      await getThemes();
    })();
  }, []);

  return (
    <DialogContent className="flex max-h-[95%] min-h-[50%] max-w-[55rem] flex-col justify-start bg-lightGray6 p-0">
      <DialogHeader className="!mb-0 flex items-center pt-1">
        <div className="flex h-full w-full items-center justify-between px-4 py-4 text-white">
          <DialogTitle className="text-xl font-medium">Store Design Theme</DialogTitle>
          <DialogClose>
            <X size={28} strokeWidth={2} className="text-white/40" />
          </DialogClose>
        </div>
      </DialogHeader>
      <Separator />

      <div className="grid flex-1 grid-cols-2 gap-4 overflow-y-auto p-4 md:grid-cols-4">
        {themes &&
          themes.map((theme, key) => (
            <div key={key} className="border-red text-center">
              <img
                className="h-auto w-full cursor-pointer border-b border-lightGray2 transition hover:shadow-lg"
                src={theme.wallpaper_url ? theme.wallpaper_url : noImageIcon}
                alt={theme.name}
                loading="lazy"
                onClick={() => handleSelectedTheme(theme)}
              />

              <div className="w-full bg-white p-[5px] text-start text-xs capitalize text-lightGray6">{theme.name}</div>
            </div>
          ))}
      </div>
      <Separator />

      <DialogFooter className="px-4 py-4">
        <DialogClose asChild>
          <Button className="h-[38px] text-base capitalize" type="button" variant="secondary">
            Close
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};

export default ThemeSelectorModal;
