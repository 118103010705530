import { useMemo, useState } from 'react';
import Checkbox from '../../../Checkbox/Checkbox';
import { useSelection } from '../../../../contexts/SelectionContext';
import { useFileNavigation } from '../../../../contexts/FileNavigationContext';

const FilesHeader = ({ unselectFiles }) => {
  const [showSelectAll, setShowSelectAll] = useState(false);

  const { selectedFiles, setSelectedFiles } = useSelection();
  const { currentPathFiles } = useFileNavigation();

  const allFilesSelected = useMemo(
    () => currentPathFiles.length > 0 && selectedFiles.length === currentPathFiles.length,
    [selectedFiles, currentPathFiles]
  );

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedFiles(currentPathFiles);
      setShowSelectAll(true);
    } else {
      unselectFiles();
    }
  };

  return (
    <div
      className="sticky top-0 z-10 flex items-center gap-1 border-b border-border bg-background px-2 py-1 text-sm font-semibold"
      onMouseOver={() => setShowSelectAll(true)}
      onMouseLeave={() => setShowSelectAll(false)}
    >
      <div className="h-[0.83em] w-[5%]">
        {(showSelectAll || allFilesSelected) && (
          <Checkbox
            checked={allFilesSelected}
            onChange={handleSelectAll}
            title="Select all"
            disabled={currentPathFiles.length === 0}
          />
        )}
      </div>
      <div className="w-[calc(65%-45px)] pl-2.5">Name</div>
      <div className="w-[20%] text-center">Modified</div>
      <div className="w-[10%] text-center">Size</div>
    </div>
  );
};

export default FilesHeader;
