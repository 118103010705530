import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Loader2 } from 'lucide-react';

import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Card, CardContent, CardHeader, CardFooter, CardDescription } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';

import useAuth from '../../hooks/useAuth';
import { cn } from '../../utils/common';

import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    email: yup.string().label('Email').required().email(),
    password: yup.string().label('Password').required().min(6),
  })
  .required();

const Login = () => {
  const title = 'VCOM - Login';
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.errorMessage || '');
  const successMessage = location.state?.successMessage || '';

  const handleLogin = async (data) => {
    try {
      setIsLoading(true);
      const response = await login(data.email, data.password);
      if (response.success) {
        setIsLoading(false);
        navigate('/store/list');
      } else {
        setIsLoading(false);
        setMessage(response.message);
      }
    } catch (err) {
      setMessage(err.message);
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="flex min-h-screen items-center justify-center bg-lightWhite px-4 py-8">
        <Card className="my-auto flex w-[25rem] flex-col rounded-md bg-white p-6 shadow-md">
          <CardHeader className="pb-2 text-center">
            <div className="flex justify-center pb-7 pt-4">
              <Link className="block" to="/">
                <img src={logo} className="max-w-[130px] brightness-[0.2]" alt="logo" />
              </Link>
            </div>
            <CardDescription className="!mb-4 text-xl font-semibold text-lightBlack">
              Login to your VCOM Account
            </CardDescription>

            {message && (
              <Alert variant="danger" className="!my-2">
                <AlertDescription dangerouslySetInnerHTML={{ __html: message }}></AlertDescription>
              </Alert>
            )}
            {successMessage && (
              <Alert variant="success" className="!my-2">
                <AlertDescription dangerouslySetInnerHTML={{ __html: successMessage }}></AlertDescription>
              </Alert>
            )}
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(handleLogin)} className="space-y-4" noValidate>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="email">
                  Email
                </Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  {...register('email')}
                  className={cn(
                    errors.email ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.email && <p className="text-sm text-red">{errors.email.message}</p>}
              </div>

              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="password">
                  Password
                </Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  {...register('password')}
                  className={cn(
                    errors.password ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.password && <p className="text-sm text-red">{errors.password.message}</p>}
              </div>

              <Button type="submit" className="!my-4 !mt-6 w-full" disabled={isLoading}>
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Log in
              </Button>
            </form>
          </CardContent>
          <CardFooter className="flex flex-col space-y-4">
            <div className="text-center">
              <Link to="/forgot" href="#" className="ml-auto text-blue-600 underline underline-offset-2">
                Forgot your password?
              </Link>
            </div>

            <div className="!mb-5 text-center text-base">
              Don&apos;t have an account?{' '}
              <Link to="/signup" className="text-blue-600 underline underline-offset-2">
                Register here
              </Link>
              .
            </div>
          </CardFooter>
        </Card>
      </main>
    </>
  );
};

export default Login;
