// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fm-button {
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.84em;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.fm-button-primary {
  background-color: #d49bf7;
  color: rgb(32, 32, 32);

  &:hover {
    background-color: #b583d4;
  }
}

.fm-button-secondary {
  background-color: #e0e0e0;
  color: black;

  &:hover {
    background-color: #c9c9c9;
  }
}

.fm-button-danger {
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;;EAEZ;IACE,eAAe;EACjB;AACF;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;;EAEtB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yBAAyB;EACzB,YAAY;;EAEZ;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yBAAyB;EACzB,YAAY;;EAEZ;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".fm-button {\n  border-radius: 5px;\n  font-weight: 600;\n  font-size: 0.84em;\n  border: none;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.fm-button-primary {\n  background-color: #d49bf7;\n  color: rgb(32, 32, 32);\n\n  &:hover {\n    background-color: #b583d4;\n  }\n}\n\n.fm-button-secondary {\n  background-color: #e0e0e0;\n  color: black;\n\n  &:hover {\n    background-color: #c9c9c9;\n  }\n}\n\n.fm-button-danger {\n  background-color: #f44336;\n  color: white;\n\n  &:hover {\n    background-color: #d32f2f;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
