const tenantDetail = async (tenantId) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/tenantdetail?id=${tenantId}`);
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

const tenantUpdate = async (data) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/tenantupdate`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (response.ok) {
          resolve(jsonResponse);
        } else {
          reject(jsonResponse.message);
        }
      } catch (error) {
        reject(new Error(error.message));
      }
    })();
  });

export { tenantDetail, tenantUpdate };
