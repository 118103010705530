// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    font-family: "Inter", sans-serif;
}
.vartualSidebar#sidebarMenu {
    width: 70px;
    background-color: #3d3e40 !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
}
.virtualMain {
    width: calc(100% - 70px);
    background-color: #535353 !important;
    height: calc(100vh - 57px);
}
.virtualAssets, .solutionList {
    background: #535353;
}
.vartualSidebar a.nav-link {
    padding: 8px 6px 14px;
    text-align: center;
    color: #d3d7db;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.vartualSidebar a.nav-link svg {
    fill: #d3d7db;
    transition: 0.3s;
    margin-bottom: 4px;
}
.vartualSidebar a.nav-link:hover svg, .vartualSidebar a.nav-link.active svg {
    fill: #d49bf7;
}
.vartualSidebar a.nav-link:hover, .vartualSidebar a.nav-link.active {
    color: #d49bf7;
}
.vartualSidebar a.nav-link img {
    display: block;
    margin: 0 auto 2px;
    filter: invert(1);
    opacity: 0.7;
}
.vartualSidebar a.nav-link i {
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/virtual/virtualsidebar.css"],"names":[],"mappings":"AACA;IACI,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,oCAAoC;IACpC,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,wBAAwB;IACxB,oCAAoC;IACpC,0BAA0B;AAC9B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,eAAe;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\nhtml, body {\n    font-family: \"Inter\", sans-serif;\n}\n.vartualSidebar#sidebarMenu {\n    width: 70px;\n    background-color: #3d3e40 !important;\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    z-index: 100;\n    padding: 48px 0 0;\n}\n.virtualMain {\n    width: calc(100% - 70px);\n    background-color: #535353 !important;\n    height: calc(100vh - 57px);\n}\n.virtualAssets, .solutionList {\n    background: #535353;\n}\n.vartualSidebar a.nav-link {\n    padding: 8px 6px 14px;\n    text-align: center;\n    color: #d3d7db;\n    font-size: 12px;\n    font-weight: 600;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.vartualSidebar a.nav-link svg {\n    fill: #d3d7db;\n    transition: 0.3s;\n    margin-bottom: 4px;\n}\n.vartualSidebar a.nav-link:hover svg, .vartualSidebar a.nav-link.active svg {\n    fill: #d49bf7;\n}\n.vartualSidebar a.nav-link:hover, .vartualSidebar a.nav-link.active {\n    color: #d49bf7;\n}\n.vartualSidebar a.nav-link img {\n    display: block;\n    margin: 0 auto 2px;\n    filter: invert(1);\n    opacity: 0.7;\n}\n.vartualSidebar a.nav-link i {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
