import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';

import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Card, CardContent, CardHeader, CardFooter } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { Loader2 } from 'lucide-react';
import { cn } from '../../utils/common';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    email: yup.string().email().label('Email').required(),
  })
  .required();

const ResendVerifyEmail = () => {
  const title = 'Account Verification';
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { accountVerification } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const response = await accountVerification(data.email);
      if (response.success) {
        setIsLoading(false);
        navigate('/login', { state: { message: response.message } });
      } else {
        setMessage(response.message);
        setIsLoading(false);
      }
    } catch (err) {
      setMessage(err.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="flex min-h-screen items-center justify-center bg-lightWhite px-4 py-8">
        <Card className="my-auto flex w-[25rem] flex-col rounded-md bg-white p-6 shadow-md">
          <CardHeader className="pb-6 text-center">
            <div className="flex justify-center pb-7 pt-4">
              <Link className="block" to="/">
                <img src={logo} className="max-w-[130px] brightness-[0.2]" alt="logo" />
              </Link>
            </div>
            <h3 className="pb-2 text-center text-xl font-semibold">Account Verification</h3>

            <p className="text-start text-[13px] text-lightBlack/70">
              No problem! Just let us know your email address and we will email you a account activation link that will
              allow you to activate your account.
            </p>

            {message && (
              <Alert variant="danger" className="!my-2">
                <AlertDescription dangerouslySetInnerHTML={{ __html: message }}></AlertDescription>
              </Alert>
            )}
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(handleForgotPassword)} className="space-y-4" noValidate>
              <div className="space-y-1">
                <Label className="!mb-0 h-auto p-0 text-sm font-normal text-darkBlueGrey" htmlFor="email">
                  Email
                </Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  {...register('email')}
                  className={cn(
                    errors.email ? 'border-red/50' : 'border-border/50',
                    'bg-white placeholder:text-border focus-visible:ring-0'
                  )}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>

              <Button type="submit" className="mt-4 w-full" disabled={isLoading}>
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Resend Account Verification Link
              </Button>
            </form>
          </CardContent>
          <CardFooter className="!mb-4 !mt-6 flex flex-col">
            <div className="text-center text-base">
              Back to
              <Link to="/login" className="ml-1 text-blue-600 underline underline-offset-2">
                Login
              </Link>
            </div>
          </CardFooter>
        </Card>
      </main>
    </>
  );
};

export default ResendVerifyEmail;
