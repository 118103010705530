import React, { Suspense, useCallback, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, Html, OrbitControls, PivotControls, useGLTF } from '@react-three/drei';
import { Euler, Matrix4, Vector3 } from 'three';

const ViewerEditor = ({
  handleCanvasDrop,
  currentRoom,
  handleTransformChange,
  selectedId,
  handleCanvasClick,
  handleSelect,
}) => {
  const handleCanvasDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <Canvas
      gl={{ preserveDrawingBuffer: true }}
      shadows
      dpr={[1, 1.5]}
      camera={{ position: [-10, 10, 10], fov: 20 }}
      onDragOver={handleCanvasDragOver}
      onDrop={handleCanvasDrop}
      onPointerMissed={handleCanvasClick}
    >
      <Suspense fallback={<LoadingScreen />}>
        <Scene data={currentRoom} />{' '}
        {currentRoom?.interior.length > 0 &&
          currentRoom.interior?.map((model) => (
            <Model
              key={model.id}
              item={model}
              selected={model.id === selectedId}
              onSelect={handleSelect}
              onTransformChange={handleTransformChange}
            />
          ))}
      </Suspense>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />

      <OrbitControls makeDefault />
      <Environment preset="apartment" />

      <color attach="background" args={['#EEEDE8']} />
    </Canvas>
  );
};

export default ViewerEditor;

const LoadingScreen = () => (
  <Html center>
    <div className="rounded bg-white p-4 text-center text-black shadow">
      <div className="mx-auto mb-2 h-8 w-8 animate-spin rounded-full"></div>
      <p>Loading model...</p>
    </div>
  </Html>
);

const Scene = ({ data }) => {
  return (
    <group>
      <Room roomData={data?.properties} />
    </group>
  );
};

const Room = ({ roomData }) => {
  if (!roomData) return null;
  const { scene } = useGLTF(roomData.modelData.file_url);
  const clone = scene.clone();

  return (
    <group>
      <primitive object={clone} />
    </group>
  );
};

const Model = ({ item, selected, onSelect, onTransformChange }) => {
  const pivotMatrixData = useRef([]);
  const {
    id,
    modelData: { position, rotation, scale, type, file_url },
  } = item;
  if (!file_url) return null;

  const { scene } = useGLTF(file_url);
  const clone = scene.clone();

  const isDragging = useRef(false);
  const meshRef = useRef();
  clone.scale.set(scale.x, scale.y, scale.z);

  const handleClick = (e) => {
    if (isDragging.current) {
      isDragging.current = false;
      return;
    }

    if (type === 'interior' || type === 'product') {
      e.stopPropagation();
      onSelect(id, item);
    }
  };

  const handleOnDrag = useCallback(
    (matrix) => {
      pivotMatrixData.current = matrix;
    },
    [id, onTransformChange, type]
  );

  const handleTransformChange = useCallback(() => {
    isDragging.current = true;

    // Get the world matrix of the PivotControls
    const worldMatrix = new Matrix4().copy(pivotMatrixData.current);

    // Extract position directly from the world matrix
    const position = new Vector3();
    position.setFromMatrixPosition(worldMatrix);
    const newPosition = {
      x: +position.x.toFixed(2),
      y: +position.y.toFixed(2),
      z: +position.z.toFixed(2),
    };

    // Extract rotation as Euler angles from the world matrix
    const euler = new Euler();
    euler.setFromRotationMatrix(worldMatrix);
    const newRotation = {
      x: +euler.x.toFixed(2),
      y: +euler.y.toFixed(2),
      z: +euler.z.toFixed(2),
    };

    onTransformChange(id, type, newPosition, newRotation);
  }, [id, onTransformChange, type]);

  return (
    <PivotControls
      depthTest={false}
      lineWidth={2}
      anchor={[0, 0, 0]}
      visible={selected}
      enabled={selected && (type === 'interior' || type === 'product')}
      onDrag={handleOnDrag}
      onDragEnd={handleTransformChange}
      disableScaling
      annotations={false}
      matrix={new Matrix4()
        .setPosition(new Vector3(position.x, position.y, position.z))
        .multiply(new Matrix4().makeRotationFromEuler(new Euler(rotation.x, rotation.y, rotation.z)))}
    >
      <group ref={meshRef} onClick={handleClick}>
        <primitive object={clone} />
      </group>
    </PivotControls>
  );
};
