import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import { SidebarInset } from '../components/ui/sidebar';

import { SideBar } from './layout/SideBar';
import { Header } from './layout/Header';

function VirtualLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();

  if (auth.isAuth()) {
    return (
      <div>
        <Header />
        <div className="flex flex-1">
          <SideBar />
          <SidebarInset>
            <Outlet />
          </SidebarInset>
        </div>
      </div>
    );
  }
  return <Navigate to={`/login?redirect=${encodeURIComponent(pathname)}`} replace />;
}

export default VirtualLayout;
