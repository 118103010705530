import * as React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '../../utils/common';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-3 py-1 text-xs  transition-colors  focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80',
        secondary: 'border-transparent bg-lightGreen4 text-darkGreen hover:bg-lightGreen4/80',
        destructive: 'border-transparent bg-red text-white shadow hover:bg-red/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

function Badge({ className, variant, ...props }) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
