import * as React from 'react';

import { cn } from '../../utils/common';

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex w-full rounded-md border bg-white px-3 py-2 text-sm shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.075)] transition-colors file:border-0 file:bg-transparent file:font-medium file:text-foreground placeholder:text-border focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export { Input };
