import { useState } from 'react';

import DeleteObjectModal from './Objects/DeleteObjectModal';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { interior, products, RightArrowIcon, storefront } from '../../../common/icon';

import { cn } from '../../../utils/common';
import { toast } from 'sonner';

const ObjectList = ({ roomList, handleCurrentRoom, selectedId, currentRoomId, handleItemSelectOnObjectList }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleShowDeleteObject = async () => {
    if (selectedId) {
      handleShowDelete();
    } else {
      toast.error('Please select atleast one object.');
    }
  };

  return (
    <div className="h-full bg-darkGray py-0.5">
      <Tabs defaultValue="objects" className="h-full w-full">
        <TabsList>
          <TabsTrigger
            className="pb-[3px] pl-[15px] pr-[25px] pt-[7px] text-[11px]"
            value="objects"
            aria-controls="example-collapse-text"
          >
            Objects
          </TabsTrigger>
        </TabsList>
        <div className="h-full overflow-y-auto bg-lightGray3 pb-12">
          <TabsContent value="objects">
            <div className="flex justify-end border-b border-lightGray7 px-2 py-2">
              <div
                onClick={handleShowDeleteObject}
                className={cn(
                  selectedId
                    ? 'cursor-pointer hover:bg-lightGray8'
                    : 'pointer-events-none cursor-not-allowed opacity-50',
                  'rounded-[3px] border border-lightGray5 bg-darkGray px-1 text-lightGray10 transition-all duration-300'
                )}
              >
                <i className="bi bi-trash"></i>
              </div>
            </div>
            <div className="h-full">
              <RoomsObjectList
                item={roomList}
                handleCurrentRoom={handleCurrentRoom}
                itemSelectedId={selectedId}
                roomId={currentRoomId}
                handleItemSelectOnObjectList={handleItemSelectOnObjectList}
              />
            </div>
          </TabsContent>{' '}
        </div>
      </Tabs>
      <DeleteObjectModal
        showDeleteModal={showDeleteModal}
        handleShowDelete={handleShowDelete}
        selectedId={selectedId}
      />
    </div>
  );
};

export default ObjectList;

const RoomsObjectList = ({ item, handleCurrentRoom, itemSelectedId, roomId, handleItemSelectOnObjectList }) => {
  const [openRooms, setOpenRooms] = useState({});
  const [openCategories, setOpenCategories] = useState({});

  const toggleRoom = (roomId) => {
    setOpenRooms((prev) => ({
      ...prev,
      [roomId]: !prev[roomId],
    }));
  };

  const toggleCategory = (roomId, category) => {
    const key = `${roomId}-${category}`;
    setOpenCategories((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleRoomClick = (roomId) => {
    handleCurrentRoom(roomId);
  };

  const handleItemClick = (roomId, item) => {
    handleItemSelectOnObjectList(roomId, item.id, item);
  };

  return (
    <div className="overflow-y-auto pb-12">
      {item.map((room) => (
        <AccordionItem
          key={room.id}
          title={room.name}
          icon={storefront}
          isOpen={openRooms[room.id]}
          onClick={() => toggleRoom(room.id)}
          onTitleClick={() => handleRoomClick(room.id)}
          isFirstLayer={true}
          isActiveRoom={roomId === room.id}
        >
          <AccordionItem
            title="Interior"
            icon={interior}
            isOpen={openCategories[`${room.id}-interior`]}
            onClick={() => toggleCategory(room.id, 'interior')}
          >
            {room.interior.map((item, index) => (
              <div
                key={`${item.id}-${index}`}
                className={cn(
                  'border-b border-lightGray7 pl-16 hover:bg-lightGray8',
                  itemSelectedId === item.id && 'bg-lightGray8'
                )}
              >
                <div className="flex cursor-pointer items-center py-2" onClick={() => handleItemClick(room.id, item)}>
                  {item.thumbnail.length > 0 ? (
                    <img src={item.thumbnail} />
                  ) : (
                    <span className="h-8 w-12 rounded-[3px] border border-black/90 bg-white"></span>
                  )}
                  <span className="ml-2 text-[11px]">{item.name}</span>
                </div>{' '}
              </div>
            ))}
          </AccordionItem>
          <AccordionItem
            title="Product"
            icon={products}
            isOpen={openCategories[`${room.id}-product`]}
            onClick={() => toggleCategory(room.id, 'product')}
          >
            {room?.product?.map((item, index) => (
              <div
                key={`${item.id}-${index}`}
                className={cn(
                  'border-b border-lightGray7 pl-16 hover:bg-lightGray8',
                  itemSelectedId === item.id && 'bg-lightGray8'
                )}
              >
                <div
                  className="flex cursor-pointer items-center py-2"
                  onClick={() => handleItemClick(room.name, 'Product', item.name)}
                >
                  {item.thumbnail.length > 0 ? (
                    <img src={item.thumbnail} />
                  ) : (
                    <span className="h-8 w-12 rounded-[3px] border border-black/90 bg-white"></span>
                  )}
                  <span className="ml-2 text-[11px]">{item.name}</span>
                </div>{' '}
              </div>
            ))}
          </AccordionItem>
        </AccordionItem>
      ))}
    </div>
  );
};

const AccordionItem = ({ title, isOpen, onClick, onTitleClick, icon, children, isFirstLayer, isActiveRoom }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick();

    if (isFirstLayer && onTitleClick) {
      onTitleClick();
    }
  };

  return (
    <div className="">
      <div
        onClick={handleClick}
        className={cn(
          'item flex cursor-pointer items-center gap-1 border-b border-lightGray7 py-3 hover:bg-lightGray8',
          isFirstLayer ? 'pl-4' : 'pl-10',
          isActiveRoom && 'bg-lightGray8'
        )}
      >
        <div className={cn('transition-transform duration-300', isOpen ? 'rotate-90' : 'rotate-0')}>
          <RightArrowIcon size={16} />
        </div>
        {<img src={icon} alt="folder" className="aspect-square h-3 w-3" />}{' '}
        <span className="ml-1 w-1/2 text-[11px]">{title}</span>
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
};
