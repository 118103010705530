import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const generateThumbnail = async (file, size = 240) => new Promise((resolve, reject) => {
  const loader = new GLTFLoader();
  const fileURL = URL.createObjectURL(file);

  loader.load(
    fileURL,
    (gltf) => {
      const { scene } = gltf;

      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(size, size);
      renderer.setPixelRatio(window.devicePixelRatio || 2);

      const ambientLight = new THREE.AmbientLight(0xffffff, 1.2);
      scene.add(ambientLight);

      const light = new THREE.DirectionalLight(0xffffff, 2);
      light.position.set(2, 2, 2);
      light.castShadow = true;
      scene.add(light);

      const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x404040, 1);
      scene.add(hemisphereLight);

      const box = new THREE.Box3().setFromObject(scene);
      const center = box.getCenter(new THREE.Vector3());
      const sizeVec = box.getSize(new THREE.Vector3());
      const maxDim = Math.max(sizeVec.x, sizeVec.y, sizeVec.z);

      const camera = new THREE.PerspectiveCamera(45, 1, 0.1, maxDim * 10);
      camera.position.set(center.x, center.y, maxDim * 1.55);
      camera.lookAt(center);

      const canvas = renderer.domElement;
      renderer.render(scene, camera);

      const thumbnailDataURL = canvas.toDataURL('image/png');

      renderer.dispose();
      URL.revokeObjectURL(fileURL);

      resolve(thumbnailDataURL);
    },
    undefined,
    (error) => reject(error),
  );
});

export default generateThumbnail;
