import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

import './solutionedit.css';
import { storefront, interior, products, apps, others } from '../../../assets/images/virtual';
import ProductList from '../../../components/virtual/solutionedit/ProductList';
import MyAssetLibrary from '../../../components/virtual/solutionedit/MyAssetLibrary';
import ObjectList from '../../../components/virtual/solutionedit/ObjectList';
import PropertyList from '../../../components/virtual/solutionedit/PropertyList';
import { getStoreSession } from '../../../services/StoreService';
import { customizationCreate, solutionDetail } from '../../../services/SolutionService';
import AppList from '../../../components/virtual/solutionedit/AppList';
import ContentList from '../../../components/virtual/solutionedit/ContentList';

import FilesProvider from '../../../contexts/FilesContext';
import SelectionProvider from '../../../contexts/SelectionContext';
import FileNavigationProvider from '../../../contexts/FileNavigationContext';
import ClipBoardProvider from '../../../contexts/ClipboardContext';
import LayoutProvider from '../../../contexts/LayoutContext';
import useTriggerAction from '../../../hooks/useTriggerAction';
import ThemesList from '../../../components/virtual/solutionedit/ThemesList';
import Actions from '../../../components/virtual/Explorer/Actions/Actions';
import useFileManager from '../../../hooks/useFileManager';
import Loader from '../../../components/Loader/Loader';

const SolutionEdit = () => {
  const [solution, setSolution] = useState({});
  const { solutionId } = useParams();
  const [showContent, setShowContent] = useState(true);
  const [contentSelected, setContentSelected] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [showThemes, setShowThemes] = useState(false);
  const [optionSelected, setOptionSelected] = useState(1);
  const [draggedItem, setDraggedItem] = useState(null);
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [selectedCanvasObject, setSelectedCanvasObject] = useState(null);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const store = getStoreSession();

  // File Management
  const triggerAction = useTriggerAction();
  const {
    files,
    handleCreateFolder,
    handleFileUploading,
    handleFileUploaded,
    handleRename,
    handleDelete,
    handlePaste,
    handleLayoutChange,
    handleRefresh,
    handleFileOpen,
    handleError,
    handleDownload,
    fileUploadConfig,
    isLoading,
  } = useFileManager();

  const handleShow = (option, data) => {
    setContentSelected(data);
    setOptionSelected(option);
    if (option === 1) {
      setShowContent(true);
      setShowProduct(false);
      setShowApp(false);
      setShowThemes(false);
    } else if (option === 2) {
      setShowContent(false);
      setShowProduct(true);
      setShowApp(false);
      setShowThemes(false);
      // setContentSelected({});
    } else if (option === 3) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(true);
      setShowThemes(false);
      // setContentSelected({});
    } else if (option === 4) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(false);
      setShowThemes(true);
    }
  };

  const getSolution = async () => {
    try {
      const response = await solutionDetail(solutionId);
      if (response.success) {
        setSolution(response.data);
      } else {
        setSolution({});
      }
    } catch (error) {
      setSolution({});
    }
  };

  const updateObjectItems = (folders, searchText, newItems) =>
    folders.map((folder) => {
      if (folder.path === searchText) {
        return {
          ...folder,
          items: [...folder.items, newItems],
        };
      }
      if (folder.items && folder.items.length > 0) {
        return {
          ...folder,
          items: updateObjectItems(folder.items, searchText, newItems),
        };
      }
      return folder;
    });

  const addItemsToObjects = (newItems, searchText) => {
    setObjects((prevData) => updateObjectItems(prevData, searchText, newItems));
  };

  const addObjectItem = async (data) => {
    try {
      const response = await customizationCreate(data);
      if (response.success) {
        toast.success('Item added to object list.');
      } else {
        toast.error('Some error occured. Please try again.');
      }
    } catch (error) {
      toast.error('Some error occured. Please try again.');
    }
  };

  const addItemsToCanvas = (newItems) => {
    canvasObjects.push(newItems);
    setCanvasObjects(canvasObjects);
  };

  const findRoot = (data, searchKey, searchValue) => {
    const searchInItems = (items, root) => {
      for (const item of items) {
        if (item[searchKey] === searchValue) {
          return root;
        }
        if (item.items && item.items.length > 0) {
          const result = searchInItems(item.items, root);
          if (result) return result;
        }
      }
      return null;
    };
    for (const root of data) {
      if (root[searchKey] === searchValue) {
        return root;
      }
      const result = searchInItems(root.items, root);
      if (result) return result;
    }
    return null; // No match found
  };

  const findObjectById = (array, id) => {
    for (const item of array) {
      if (item.id === id) {
        return item;
      }

      if (item.items && item.items.length > 0) {
        const found = findObjectById(item.items, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!selectedObject) {
      e.dataTransfer.dropEffect = 'none';
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    // register event position
    if (selectedObject) {
      // add image
      setCanvasObjects(
        canvasObjects.concat([
          {
            src: draggedItem.src,
            height: draggedItem.height,
            width: draggedItem.width,
            depth: draggedItem.depth,
            id: draggedItem.id,
            name: draggedItem.name,
            x: -1,
            y: 1,
            z: 0,
            rotation_x: draggedItem.rotation_x,
            rotation_y: draggedItem.rotation_y,
            rotation_z: draggedItem.rotation_z,
          },
        ])
      );
      setSelectedCanvasObject({
        src: draggedItem.src,
        height: draggedItem.height,
        width: draggedItem.width,
        id: draggedItem.id,
        name: draggedItem.name,
        depth: draggedItem.depth,
        x: draggedItem.position_x,
        y: draggedItem.position_y,
        z: draggedItem.position_z,
        rotation_x: draggedItem.rotation_x,
        rotation_y: draggedItem.rotation_y,
        rotation_z: draggedItem.rotation_z,
      });
      // const firstPart = selectedObject ? selectedObject?.path?.split('/')[1] : 'Store Front';
      const rootObj = findRoot(objects, 'id', selectedObject.id);
      if (draggedItem?.isProduct) {
        const newItem = {
          id: draggedItem.id,
          name: draggedItem.name,
          isFolder: false,
          src: draggedItem.src,
          path: `/${rootObj.name}/Products/${draggedItem.name}`,
          type: 'Product',
        };
        addItemsToObjects(newItem, `/${rootObj.name}/Products`);
        const newObject = {
          store_id: store.id,
          theme_room_id: rootObj.id,
          market_product_id: draggedItem.id,
          solution_id: solution.id,
          measure_w: draggedItem.width,
          measure_d: draggedItem.depth,
          measure_h: draggedItem.height,
          position_x: -1,
          position_y: 1,
          position_z: 0,
          rotation_x: draggedItem.rotation_x,
          rotation_y: draggedItem.rotation_y,
          rotation_z: draggedItem.rotation_z,
          glue_by: 'test',
          is_product: true,
        };
        addObjectItem(newObject);
      } else {
        let objType = 'Interior';
        if (draggedItem.type && (draggedItem.type === 'Audio' || draggedItem.type === 'Spawn')) {
          objType = 'Others';
        }
        const newItem = {
          id: draggedItem.id,
          name: draggedItem.name,
          isFolder: false,
          src: draggedItem.src,
          path: `/${rootObj.name}/${objType}/${draggedItem.name}`,
          type: draggedItem.type ? draggedItem.type : 'Interior',
        };
        addItemsToObjects(newItem, `/${rootObj.name}/${objType}`);
        const newObject = {
          store_id: store.id,
          theme_room_id: rootObj.id,
          theme_object_id: draggedItem.id,
          solution_id: solution.id,
          measure_w: draggedItem.width,
          measure_d: draggedItem.depth,
          measure_h: draggedItem.height,
          position_x: -1,
          position_y: 1,
          position_z: 0,
          rotation_x: draggedItem.rotation_x,
          rotation_y: draggedItem.rotation_y,
          rotation_z: draggedItem.rotation_z,
          glue_by: 'test',
          is_product: false,
          file_share_url: draggedItem.src,
          name: draggedItem.name,
          theme_id: solution.theme_id,
        };
        addObjectItem(newObject);
      }
    } else {
      toast.error('Please select atleast one room.');
    }
  };

  useEffect(() => {
    (async () => {
      await getSolution();
    })();
  }, [solutionId]);

  useEffect(() => {
    if (solution.ThemeStyle) {
      const themeRooms = solution.ThemeStyle?.ThemeRoom;
      if (themeRooms && themeRooms.length > 0) {
        const availableRooms = [];
        themeRooms.forEach((themeRoom) => {
          const roomObj = {
            id: themeRoom.id,
            name: themeRoom.name,
            isFolder: true,
            src: storefront,
            path: `/${themeRoom.name}`,
            isDefault: themeRoom.isDefault,
            items: [
              {
                id: `${themeRoom.id}_1`,
                name: 'Interior',
                isFolder: true,
                src: interior,
                path: `/${themeRoom.name}/Interior`,
                items: [],
              },
              {
                id: `${themeRoom.id}_2`,
                name: 'Products',
                isFolder: true,
                src: products,
                path: `/${themeRoom.name}/Products`,
                items: [],
              },
              {
                id: `${themeRoom.id}_3`,
                name: 'Apps',
                isFolder: true,
                src: apps,
                path: `/${themeRoom.name}/Apps`,
                items: [],
              },
              {
                id: `${themeRoom.id}_4`,
                name: 'Others',
                isFolder: true,
                src: others,
                path: `/${themeRoom.name}/Others`,
                items: [],
              },
            ],
          };
          availableRooms.push(roomObj);
        });
        setObjects(availableRooms);
        themeRooms.forEach((themeRoom) => {
          const customizations = themeRoom.Customization;
          if (customizations && customizations.length > 0) {
            customizations.forEach((customization) => {
              const object = customization.Object;
              if (object) {
                let objType = 'Interior';
                if (object.ObjectType && (object.ObjectType.name === 'Audio' || object.ObjectType.name === 'Spawn')) {
                  objType = 'Others';
                }
                const objObj = {
                  id: customization.id,
                  name: object.name,
                  isFolder: false,
                  src: object.file_share_url,
                  path: `/${themeRoom.name}/${objType}/${object.name}`,
                  type: object.ObjectType ? object.ObjectType.name : 'Interior',
                  width: customization.measure_w,
                  height: customization.measure_h,
                  depth: customization.measure_d,
                  x: customization.position_x,
                  y: customization.position_y,
                  z: customization.position_z,
                  rotation_x: customization.rotation_x,
                  rotation_y: customization.rotation_y,
                  rotation_z: customization.rotation_z,
                };
                addItemsToObjects(objObj, `/${themeRoom.name}/${objType}`);
                const canvasObj = {
                  src: object.file_share_url,
                  x: customization.position_x,
                  y: customization.position_y,
                  z: customization.position_z,
                  height: customization.measure_h,
                  width: customization.measure_w,
                  depth: customization.measure_d,
                  id: customization.id,
                  name: object.name,
                  rotation_x: customization.rotation_x,
                  rotation_y: customization.rotation_y,
                  rotation_z: customization.rotation_z,
                };
                addItemsToCanvas(canvasObj);
              }
              const product = customization.Product;
              if (product) {
                const prodObj = {
                  id: customization.id,
                  name: product.name,
                  isFolder: false,
                  src: product.Media && product.Media.length > 0 ? product.Media[0]?.file_url : '',
                  path: `/${themeRoom.name}/Products/${product.name}`,
                  type: 'Product',
                  width: customization.measure_w,
                  height: customization.measure_h,
                  depth: customization.measure_d,
                  x: customization.position_x,
                  y: customization.position_y,
                  z: customization.position_z,
                  rotation_x: customization.rotation_x,
                  rotation_y: customization.rotation_y,
                  rotation_z: customization.rotation_z,
                };
                addItemsToObjects(prodObj, `/${themeRoom.name}/Products`);
                const canvasProd = {
                  src: product.Media && product.Media.length > 0 ? product.Media[0]?.file_url : '',
                  x: customization.position_x,
                  y: customization.position_y,
                  z: customization.position_z,
                  height: customization.measure_h,
                  width: customization.measure_w,
                  depth: customization.measure_d,
                  id: customization.id,
                  name: product.name,
                  rotation_x: customization.rotation_x,
                  rotation_y: customization.rotation_y,
                  rotation_z: customization.rotation_z,
                };
                addItemsToCanvas(canvasProd);
              }
            });
          }
        });
      }
    }
  }, [solution]);

  useEffect(() => {
    if (!selectedObject?.isFolder) {
      const matchingObject = canvasObjects.find((obj) => obj.id === selectedObject?.id);
      setSelectedCanvasObject(matchingObject);
    }
  }, [selectedObject]);

  useEffect(() => {
    if (selectedCanvasObject) {
      const findObj = findObjectById(objects, selectedCanvasObject.id);
      setSelectedObject(findObj);
    }
  }, [selectedCanvasObject]);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="virtualAssets">
        <Row className="m-0">
          <div className="virtualCanvasArea p-0">
            <div className="canvasFrame" onDragOver={handleDragOver} onDrop={handleDrop}>
              {/* <DndProvider backend={HTML5Backend}>
                <Canvas camera={{ position: [0, 0, 10], fov: 50 }} fallback={<div>Sorry no WebGL supported!</div>}>
                  <ambientLight />
                  <directionalLight position={[0, 10, 5]} intensity={1} />
                  {canvasObjects.map((canvasObject, i) => {
                    const isGLTFModel =
                      canvasObject.src.split('.').at(-1) === 'glb' || canvasObject.src.split('.').at(-1) === 'gltf';
                    if (isGLTFModel) {
                      return (
                        <GLTFViewer
                          key={i}
                          modelProps={canvasObject}
                          isSelected={canvasObject.id === selectedCanvasObject?.id}
                          onSelect={() => {
                            setSelectedCanvasObject(canvasObject);
                          }}
                          onChange={(newAttrs) => {
                            const objs = canvasObjects.slice();
                            objs[i] = newAttrs;
                            setCanvasObjects(objs);
                            setSelectedCanvasObject(newAttrs);
                          }}
                        />
                      );
                    }
                    const isAudio = canvasObject.src.split('.').at(-1) === 'mp3';
                    if (isAudio) {
                      return '';
                    }
                    // const isOBJModel = canvasObject.src.split('.').at(-1) === 'obj';
                    // if (isOBJModel) {
                    //   return (
                    //     <OBJViewer
                    //       key={i}
                    //       modelProps={canvasObject}
                    //       isSelected={canvasObject.id === selectedCanvasObject?.id}
                    //       onSelect={() => {
                    //         setSelectedCanvasObject(canvasObject);
                    //       }}
                    //       onChange={(newAttrs) => {
                    //         const objs = canvasObjects.slice();
                    //         objs[i] = newAttrs;
                    //         setCanvasObjects(objs);
                    //         setSelectedCanvasObject(newAttrs);
                    //       }}
                    //     />
                    //   );
                    // }
                    return (
                      <ImageViewer
                        key={i}
                        imgProps={canvasObject}
                        isSelected={canvasObject.id === selectedCanvasObject?.id}
                        onSelect={() => {
                          setSelectedCanvasObject(canvasObject);
                        }}
                        onChange={(newAttrs) => {
                          const objs = canvasObjects.slice();
                          objs[i] = newAttrs;
                          setCanvasObjects(objs);
                          setSelectedCanvasObject(newAttrs);
                        }}
                      />
                    );
                  })}
                </Canvas>
              </DndProvider> */}
            </div>
            <div className="myAssets">
              <div className="searchProduct">
                <Row className="h-100">
                  <FilesProvider filesData={files} onError={handleError}>
                    <FileNavigationProvider defaultPath="">
                      <SelectionProvider onDownload={handleDownload}>
                        <ClipBoardProvider onPaste={handlePaste}>
                          <LayoutProvider layout="grid">
                            <div className="leftAssets h-100 border">
                              <MyAssetLibrary
                                optionSelected={optionSelected}
                                handleShow={handleShow}
                                contentSelected={contentSelected}
                              />
                            </div>
                            <div className="rightAssets h-100 border ps-0">
                              {showContent && (
                                <>
                                  <Loader isLoading={isLoading} />
                                  <ContentList
                                    setDraggedItem={setDraggedItem}
                                    contentSelected={contentSelected}
                                    triggerAction={triggerAction}
                                    onFileOpen={handleFileOpen}
                                    onLayoutChange={handleLayoutChange}
                                    onRefresh={handleRefresh}
                                    onCreateFolder={handleCreateFolder}
                                    onRename={handleRename}
                                    enableFilePreview
                                  />
                                </>
                              )}
                              {showProduct && <ProductList setDraggedItem={setDraggedItem} />}
                              {showApp && <AppList />}
                              {showThemes && <ThemesList />}
                            </div>
                            <Actions
                              fileUploadConfig={fileUploadConfig}
                              onFileUploading={handleFileUploading}
                              onFileUploaded={handleFileUploaded}
                              onDelete={handleDelete}
                              onRefresh={handleRefresh}
                              maxFileSize={50485760}
                              filePreviewPath={`${process.env.REACT_APP_BLOB_CONTAINER_URL}${store.id}`}
                              acceptedFileTypes=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .svg, .SVG, .glb, .GLB, .mp3, .MP3, .mp4, .MP4"
                              triggerAction={triggerAction}
                            />
                          </LayoutProvider>
                        </ClipBoardProvider>
                      </SelectionProvider>
                    </FileNavigationProvider>
                  </FilesProvider>
                </Row>
              </div>
            </div>
          </div>
          <div className="sidebarCanvas p-0">
            <div className="topSideBar">
              <PropertyList selectedCanvasObject={selectedCanvasObject} />
            </div>
            <div className="bottomSideBar">
              <ObjectList
                objects={objects}
                setObjects={setObjects}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
              />
            </div>
          </div>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default SolutionEdit;
