import { MdClose } from 'react-icons/md';
import { useEffect, useRef } from 'react';
import './Modal.css';

const Modal = ({
  children,
  show,
  setShow,
  heading,
  dialogWidth = '25%',
  contentClassName = '',
  closeButton = true,
}) => {
  const modalRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      modalRef.current.showModal();
    } else {
      modalRef.current.close();
    }
  }, [show]);

  return (
    <dialog
      ref={modalRef}
      className="dialog rounded-md bg-background"
      style={{ width: dialogWidth }}
      onKeyDown={handleKeyDown}
    >
      <div className="flex items-center justify-between px-4 py-2">
        <span className="flex font-bold text-foreground">{heading}</span>
        {closeButton && (
          <MdClose
            size={28}
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-full hover:bg-accent"
            title="Close"
          />
        )}
      </div>
      {children}
    </dialog>
  );
};

export default Modal;
