import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Sidebar, SidebarContent } from '../ui/sidebar';
import { navMain } from '../../common/data';

export const SideBar = ({ ...props }) => {
  const [activeItem, setActiveItem] = useState(navMain[0]);

  return (
    <Sidebar collapsible="icon" className="top-[--header-height] !h-[calc(100svh-var(--header-height))]" {...props}>
      <SidebarContent>
        <ul className="mt-12 flex flex-col gap-4 md:gap-1">
          {navMain.map((item) => (
            <ul
              key={item.title}
              onClick={() => {
                setActiveItem(item);
              }}
              className={`${activeItem.title === item.title ? 'text-pink2' : 'text-white'}`}
            >
              <NavLink end to={item.url} className="flex flex-row items-center gap-1 px-4 md:flex-col md:px-0 md:py-2">
                <span>{item.icon}</span>
                <div className="text-xs font-semibold">{item.title}</div>
              </NavLink>
            </ul>
          ))}
        </ul>
      </SidebarContent>
    </Sidebar>
  );
};
