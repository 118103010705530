import { useRef, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import ReactLoading from 'react-loading';
import { Button } from '../../../../ui/button';
import UploadItem from './UploadItem';
import { useFileNavigation } from '../../../../../contexts/FileNavigationContext';
import getFileExtension from '../../../../../utils/getFileExtension';
import getDataSize from '../../../../../utils/getDataSize';
import { useFiles } from '../../../../../contexts/FilesContext';

const UploadFileAction = ({ fileUploadConfig, maxFileSize, acceptedFileTypes, onFileUploading, onFileUploaded }) => {
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState({});
  const { currentFolder, currentPathFiles } = useFileNavigation();
  const { onError } = useFiles();
  const fileInputRef = useRef(null);

  const handleChooseFileKeyDown = (e) => {
    if (e.key === 'Enter') {
      fileInputRef.current.click();
    }
  };

  const checkFileError = (file) => {
    if (acceptedFileTypes && !acceptedFileTypes.includes(getFileExtension(file.name))) {
      return 'File type is not allowed.';
    }

    if (currentPathFiles.some((item) => item.name.toLowerCase() === file.name.toLowerCase() && !item.isDirectory)) {
      return 'File already exists.';
    }

    if (maxFileSize && file.size > maxFileSize) {
      return `Maximum upload size is ${getDataSize(maxFileSize, 0)}.`;
    }
    return null;
  };

  const setSelectedFiles = (selectedFiles) => {
    let newFiles = selectedFiles.filter(
      (file) => !files.some((fileData) => fileData.file.name.toLowerCase() === file.name.toLowerCase())
    );

    if (newFiles.length > 0) {
      const updatedFiles = newFiles.map((file) => {
        const appendData = onFileUploading(file, currentFolder);
        const error = checkFileError(file);
        if (error) onError({ type: 'upload', message: error }, file);
        return { file, appendData, ...(error && { error }) };
      });
      setFiles((prev) => [...prev, ...updatedFiles]);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setSelectedFiles(Array.from(e.dataTransfer.files));
  };

  const handleChooseFile = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  return (
    <div className="flex gap-4 p-4">
      <div className="w-full">
        <div
          className={`flex h-56 flex-col items-center justify-center rounded-lg border-2 border-dashed transition-colors ${isDragging ? 'border-foreground' : 'border-muted'}`}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
        >
          <AiOutlineCloudUpload size={30} className="text-muted-foreground" />
          <span className="text-sm text-muted-foreground">Drag files to upload</span>
        </div>
        <div className="mt-4 flex justify-center">
          <Button variant="secondary" onKeyDown={handleChooseFileKeyDown}>
            <label htmlFor="chooseFile" className="cursor-pointer">
              Choose File
            </label>
            <input
              ref={fileInputRef}
              type="file"
              id="chooseFile"
              className="hidden"
              onChange={handleChooseFile}
              multiple
              accept={acceptedFileTypes}
            />
          </Button>
        </div>
      </div>
      {files.length > 0 && (
        <div className="w-3/5 text-foreground">
          <div className="flex items-center gap-2">
            {Object.values(isUploading).some((uploading) => uploading) ? (
              <>
                <h2 className="text-sm">Uploading</h2>
                <ReactLoading type="cylon" color="currentColor" height={18} width={20} />
              </>
            ) : (
              <h2 className="text-sm">Completed</h2>
            )}
          </div>
          <ul className="mt-3 h-56 overflow-y-auto text-sm">
            {files.map((fileData, index) => (
              <UploadItem
                key={index}
                index={index}
                fileData={fileData}
                setFiles={setFiles}
                fileUploadConfig={fileUploadConfig}
                setIsUploading={setIsUploading}
                onFileUploaded={onFileUploaded}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UploadFileAction;
