import { useEffect, useRef, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { FaCheck } from 'react-icons/fa6';

const SubMenu = ({ subMenuRef, list, position = 'right' }) => (
  <ul ref={subMenuRef} className={`absolute left-32 top-0 z-50 rounded-md border bg-popover p-1 shadow-md ${position}`}>
    {list?.map((item) => (
      <li
        key={item.title}
        onClick={item.onClick}
        className="relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent hover:text-accent-foreground"
      >
        <span className="w-[13px] text-foreground">{item.selected && <FaCheck size={13} />}</span>
        {item.icon}
        <span>{item.title}</span>
      </li>
    ))}
  </ul>
);

const ContextMenu = ({ filesViewRef, contextMenuRef, menuItems, visible, clickPosition }) => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(null);
  const [subMenuPosition, setSubMenuPosition] = useState('right');

  const subMenuRef = useRef(null);

  const contextMenuPosition = () => {
    const { clickX, clickY } = clickPosition;

    const container = filesViewRef.current;
    const containerRect = container.getBoundingClientRect();

    // Context menu size
    const contextMenuContainer = contextMenuRef.current.getBoundingClientRect();
    const menuWidth = contextMenuContainer.width;
    const menuHeight = contextMenuContainer.height;

    // Check if there is enough space at the right for the context menu
    const leftToCursor = clickX;
    const isRight = containerRect.right - leftToCursor > menuWidth;
    const isLeft = !isRight;

    const topToCursor = clickY;
    const isTop = window.innerHeight - topToCursor > menuHeight;
    const bottom = !isTop;

    if (isRight) {
      setLeft(`${leftToCursor}px`);
      setSubMenuPosition('right');
    } else if (isLeft) {
      // Location: -width of the context menu from cursor's position i.e. left side
      setLeft(`${leftToCursor - menuWidth}px`);
      setSubMenuPosition('left');
    }

    if (isTop) {
      setTop(`${topToCursor}px`);
    } else if (bottom) {
      setTop(`${topToCursor - menuHeight}px`);
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseOver = (index) => {
    setActiveSubMenuIndex(index);
  };

  useEffect(() => {
    if (visible && contextMenuRef.current) {
      contextMenuPosition();
    } else {
      setTop(0);
      setLeft(0);
      setActiveSubMenuIndex(null);
    }
  }, [visible]);

  if (visible) {
    return (
      <div
        ref={contextMenuRef}
        onContextMenu={handleContextMenu}
        onClick={(e) => e.stopPropagation()}
        className={`fixed z-50 rounded-md border bg-popover p-1 text-popover-foreground shadow-md transition-opacity duration-100 ease-linear ${top ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'}`}
        style={{
          top,
          left,
        }}
      >
        <div>
          <ul className="m-0 flex list-none flex-col gap-1 p-0">
            {menuItems
              .filter((item) => !item.hidden)
              .map((item, index) => {
                const hasChildren = Object.prototype.hasOwnProperty.call(item, 'children');
                const activeSubMenu = activeSubMenuIndex === index && hasChildren;
                return (
                  <div key={item.title}>
                    <li
                      onClick={item.onClick}
                      className={`relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none ${hasChildren ? 'pr-4' : ''} ${activeSubMenu ? 'bg-accent text-accent-foreground' : 'hover:bg-accent hover:text-accent-foreground'} ${item.className ?? ''} ${item.disabled ? 'pointer-events-none opacity-50' : ''}`}
                      onMouseOver={() => handleMouseOver(index)}
                    >
                      {item.icon}
                      <span className="mr-2">{item.title}</span>
                      {hasChildren && (
                        <>
                          <ChevronRight className="ml-auto h-4 w-4" />
                          {activeSubMenu && (
                            <SubMenu subMenuRef={subMenuRef} list={item.children} position={subMenuPosition} />
                          )}
                        </>
                      )}
                    </li>
                    {item.divider && <div className="my-1 h-px bg-muted"></div>}
                  </div>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default ContextMenu;
