import { useEffect, useState } from 'react';
import {
  Button, Collapse, Accordion, Row, Col, Form, InputGroup, ListGroup, Image,
} from 'react-bootstrap';
import Surface from '../../../assets/images/properties/Surface.png';
import SurfaceBottom from '../../../assets/images/properties/SurfaceBottom.png';
import SurfaceTop from '../../../assets/images/properties/SurfaceTop.png';
import SurfaceLeft from '../../../assets/images/properties/SurfaceLeft.png';
import SurfaceRight from '../../../assets/images/properties/SurfaceRight.png';
import SurfaceFront from '../../../assets/images/properties/SurfaceFront.png';
import SurfaceBack from '../../../assets/images/properties/SurfaceBack.png';

const PropertyList = ({ selectedCanvasObject }) => {
  const [open, setOpen] = useState(true);
  const [canvasObject, setCanvsObject] = useState({
    id: '',
    name: '',
    src: '',
    width: '',
    height: '',
    x: '',
    y: '',
  });

  const handleOpenProperty = () => {
    setOpen(!open);
  };

  const pixelToMM = (pixVal) => (pixVal * 0.2645833333).toFixed(2);

  useEffect(() => {
    if (selectedCanvasObject) {
      setCanvsObject(selectedCanvasObject);
    }
  }, [selectedCanvasObject]);

  return (
    <>
      <div className="properties">
        <Button
          onClick={handleOpenProperty}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          Properties
        </Button>
      </div>
      <div className="sidebarCard scrollbarCard">
        <Collapse in={open}>
          <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Measures (mm)</Accordion.Header>
              <Accordion.Body>
                <Row className="m-0">
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label htmlFor='measure_w'>W</Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        max={99999}
                        name="measure_w"
                        id="measure_w"
                        onChange={(e) => setCanvsObject({ ...canvasObject, width: e.target.value })}
                        value={pixelToMM(canvasObject?.width)}
                      />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label htmlFor='measure_d'>D</Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        max={99999}
                        name="measure_d"
                        id="measure_d"
                      />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                    <div className="linkIcon">
                      <Button variant="secondary"><i className="bi bi-link-45deg"></i></Button>
                    </div>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label htmlFor='measure_h'>H</Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        max={99999}
                        name="measure_h"
                        id="measure_h"
                        value={pixelToMM(canvasObject?.height)}
                        onChange={(e) => setCanvsObject({ ...canvasObject, height: e.target.value })}
                      />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Position</Accordion.Header>
              <Accordion.Body>
                <Row className="m-0">
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label htmlFor='position_x'>X</Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        max={99999}
                        name="position_x"
                        id="position_x"
                        value={canvasObject.x || ''}
                        onChange={(e) => setCanvsObject({ ...canvasObject, x: e.target.value })}
                      />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label htmlFor='position_y'>Y</Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        max={99999}
                        name="position_y"
                        id="position_y"
                        value={canvasObject.y || ''}
                        onChange={(e) => setCanvsObject({ ...canvasObject, y: e.target.value })}
                      />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label htmlFor='position_z'>Z</Form.Label>
                      <Form.Control type="number" min={1} max={99999} name="position_z" id="position_z" />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Rotation</Accordion.Header>
              <Accordion.Body>
                <Row className="m-0">
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>X</Form.Label>
                      <Form.Control type="number" name="rotation_x" id="rotation_x" />
                      <Button variant="secondary"><i className="bi bi-arrow-clockwise"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>Y</Form.Label>
                      <Form.Control type="number" name="rotation_y" id="rotation_y" />
                      <Button variant="secondary"><i className="bi bi-arrow-clockwise"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>Z</Form.Label>
                      <Form.Control type="number" name="rotation_z" id="rotation_z" />
                      <Button variant="secondary"><i className="bi bi-arrow-clockwise"></i></Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Glue By</Accordion.Header>
              <Accordion.Body>
                <ListGroup horizontal className="align-items-center">
                  <ListGroup.Item className="bg-transparent border-0 p-0">Surface</ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={Surface} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceBottom} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceTop} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceLeft} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceRight} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceFront} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceBack} /></Button>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Materials</Accordion.Header>
              <Accordion.Body>
                <InputGroup className="align-items-center pe-4">
                  <Form.Label>Primary texture</Form.Label>
                  <Form.Control type="text" name="primaryTexture" id="primaryTexture" />
                  <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Commerce</Accordion.Header>
              <Accordion.Body>
                <InputGroup className="align-items-center pe-4">
                  <Form.Label>Product theme</Form.Label>
                  <Form.Control type="text" name="productTheme" id="productTheme" />
                  <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                </InputGroup>
                <InputGroup className="align-items-center mt-2 pe-4">
                  <Form.Label className="pe-4 me-3">Buyable</Form.Label>
                  <Form.Check
                    type="switch"
                    name="buyable"
                    id="buyable"
                  />
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Collapse>
      </div>
    </>
  );
};

export default PropertyList;
