import getFileExtension from './getFileExtension';

const formatFileName = (name, maxLength) => {
  if (name.length <= maxLength) return name;

  const dotsLength = 3;
  const extMaxLength = 4;
  const fileMaxLength = maxLength - (dotsLength + extMaxLength);
  const extension = getFileExtension(name);

  return `${name.slice(0, fileMaxLength + (extension.length % extMaxLength))}${Array(dotsLength).fill('.').join('')}${extension}`;
};

export default formatFileName;
